import avatar1 from "../../../architectuiassets/utils/images/avatars/1.jpg";
import avatar2 from "../../../architectuiassets/utils/images/avatars/2.jpg";
import avatar3 from "../../../architectuiassets/utils/images/avatars/3.jpg";
import avatar4 from "../../../architectuiassets/utils/images/avatars/4.jpg";

export const Submissions = {
	All: [
		{
			id: 1,
			name: "Shahzaib Khanzada",
			role: "Enumerator",
			district: "Lahore",
			status: "Approved",
			facilityCenter: "Moddel Town, Lahore",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Jazz Customer Feedback",
			resubmission: true
		},
		{
			id: 2,
			name: "Shahzaib Khanzada",
			role: "Enumerator",
			district: "Lahore",
			status: "Approved",
			facilityCenter: "Moddel Town, Lahore",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Jazz Customer Feedback",
			resubmission: true
		},
		{
			id: 3,
			name: "Shahzaib Khanzada",
			role: "Enumerator",
			district: "Lahore",
			status: "Approved",
			facilityCenter: "Moddel Town, Lahore",
			dos: "20-04-2020",
			profilePhoto: avatar3,
			title: "Jazz Customer Feedback"
		},
		{
			id: 4,
			name: "Shahzaib Khanzada",
			role: "Enumerator",
			district: "Lahore",
			status: "Approved",
			facilityCenter: "Moddel Town, Lahore",
			dos: "20-04-2020",
			profilePhoto: avatar4,
			title: "Jazz Customer Feedback"
		},
		{
			id: 5,
			name: "Shahzaib Khanzada",
			role: "Enumerator",
			district: "Lahore",
			status: "Approved",
			facilityCenter: "Moddel Town, Lahore",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Jazz Customer Feedback",
			resubmission: true
		},
		{
			id: 6,
			name: "Shahzaib Khanzada",
			role: "Enumerator",
			district: "Lahore",
			status: "Approved",
			facilityCenter: "Family Planing Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Jazz Customer Feedback"
		}
	]
};
