import React from "react";
import { Card, CardHeader, Row, Col, Button, Table, CardFooter } from "reactstrap";
import { Pagination } from "../Pagination";

const handleChangePage = () => {
	"";
};

export const TimelineTable = ({ data }) => {
	return (
		<Row className="mt-3">
			<Col md="12">
				<Card className="main-card mb-3">
					<CardHeader>Submissions</CardHeader>
					<Table responsive hover striped borderless className="align-middle mb-0">
						<thead>
							<tr>
								<th className="text-center">#</th>
								<th>Name</th>
								<th className="text-center">District</th>
								<th className="text-center">Facility Center</th>
								<th className="text-center">Status</th>
								<th className="text-center">Date of Submission</th>
								<th className="text-center">Actions</th>
							</tr>
						</thead>
						<tbody>
							{data ? (
								data.map((item, index) => (
									<tr>
										<td className="text-center text-muted">{item.id}</td>
										<td>
											<div className="widget-content p-0">
												<div className="widget-content-wrapper">
													<div className="widget-content-left mr-3">
														<div className="widget-content-left">
															<img
																width={40}
																className="rounded-circle"
																src={item.profilePhoto}
																alt=""
															/>
														</div>
													</div>
													<div className="widget-content-left flex2">
														<div className="widget-heading">{item.name}</div>
														<div className="widget-subheading opacity-7">{item.role}</div>
													</div>
												</div>
											</div>
										</td>
										<td className="text-center">{item.district}</td>
										<td className="text-center">{item.facilityCenter}</td>
										<td className="text-center">
											<div className="badge badge-warning">{item.status}</div>
										</td>
										<td className="text-center">{item.dos}</td>
										<td className="text-center">
											<Button size="sm" color="primary">
												Details
											</Button>
										</td>
									</tr>
								))
							) : (
								<div className="text-center col-12 align-content-center align-items-center">
									<h4 className="align-self-center">No Submissions</h4>
								</div>
							)}
						</tbody>
					</Table>
					<CardFooter className="d-block text-center">
						{/* <Button className="btn-wide" color="success">
							Load More
						</Button> */}
						<Row className="justify-content-end col-12">
							<Pagination pageCount={10} handlePageClick={handleChangePage()} />
						</Row>
					</CardFooter>
				</Card>
			</Col>
		</Row>
	);
};
