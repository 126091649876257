// Stateless functional Component
import React from "react";
import LoaderSVG from "../../assets/images/loader.svg";

const Loader = props => {
	const { loading } = props;
	if (loading) {
		return (
			<div className="loader">
				<img src={LoaderSVG} className="loadersvg" img="source" alt="loading" />
			</div>
		);
	} else return null;
};

Loader.defaultProps = {
	loading: false,
	size: "md"
};

export default Loader;
