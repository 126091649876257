import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import Header from "./AppHeader/index";
import AppSideBar from "./AppSidebar/index";
import ResizeDetector from "react-resize-detector";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";

const MainLayout = ({ component: Component, topMenu, leftMenu, topProductSelection, ...rest }) => {
	let {
		colorScheme,
		enableFixedHeader,
		enableFixedSidebar,
		enableFixedFooter,
		enableClosedSidebar,
		closedSmallerSidebar,
		enableMobileMenu,
		enablePageTabsAlt
	} = rest;

	return (
		<ResizeDetector
			handleWidth
			render={({ width }) => (
				<Fragment>
					<div
						className={cx(
							"app-container app-theme-" + colorScheme,
							{ "fixed-header": enableFixedHeader },
							{ "fixed-sidebar": enableFixedSidebar || width < 1250 },
							{ "fixed-footer": enableFixedFooter },
							{ "closed-sidebar": enableClosedSidebar || width < 1250 },
							{ "closed-sidebar-mobile": closedSmallerSidebar || width < 1250 },
							{ "sidebar-mobile-open": enableMobileMenu },
							{ "body-tabs-shadow-btn": enablePageTabsAlt }
						)}
					>
						<Route
							{...rest}
							render={matchProps => {
								return (
									<Fragment>
										<Header
											menuItems={topMenu ? topMenu : null}
											topProductSelection={topProductSelection ? true : false}
										/>
										<div className="app-main">
											<AppSideBar menuItems={leftMenu ? leftMenu : null} />
											<div className="app-main__outer">
												<div className="app-main__inner p-0">
													<Component {...matchProps} />
													{/* <div className="fixItems">
                            <a
                              href="#"
                              title="Analysis"
                              className="analysis active"
                            >
                              <i className="pe-7s-display1"></i>
                              <i className="pe-7s-search search"></i>
                            </a>
                            <a
                              href="#"
                              title="Monitoring"
                              className="monitoring"
                            >
                              <i className="pe-7s-stopwatch"></i>
                            </a>
                          </div> */}
												</div>
											</div>
										</div>
									</Fragment>
								);
							}}
						/>
					</div>
				</Fragment>
			)}
		/>
	);
};
const mapStateToProp = state => ({
	colorScheme: state.ThemeOptions.colorScheme,
	enableFixedHeader: state.ThemeOptions.enableFixedHeader,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	enableFixedFooter: state.ThemeOptions.enableFixedFooter,
	enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
	enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
	enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt
});

export default withRouter(connect(mapStateToProp)(MainLayout));
