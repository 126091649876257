import React, { PureComponent } from "react";
import { Card, CardHeader, CardFooter, Row } from "reactstrap";
import Table from "../common/table";
import Data from "../data/tabular_data.json";
import { prepareTableData, sortTableData } from "../utils/table_data";
import { Pagination } from "../../../components/Pagination/index";

class UsersTable extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			sortBy: "id",
			sortOrder: "asc",
			currentPage: 0
		};
		this.tabularData = Data.map(row => ({ ...row, id: parseInt(row.id, 10) }));
		this.USERS_PER_PAGE = 100;
	}

	handleChangePage = ({ selected }) => {
		this.setState({ currentPage: selected });
	};

	filterByType = data => {
		let { selectedTypes } = this.props;
		if (selectedTypes.length === 0) return data;
		selectedTypes = selectedTypes.map(type => type.toLowerCase());
		return data.filter(row => selectedTypes.includes(row.type.toLowerCase()));
	};

	filterBySegment = data => {
		let { selectedSegments } = this.props;
		if (selectedSegments.length === 0) return data;
		selectedSegments = selectedSegments.map(segment => segment.toLowerCase());
		return data.filter(row => selectedSegments.includes(row.grade.toLowerCase()));
	};

	handleSort = (sortBy, sortOrder) => {
		this.setState({ sortBy, sortOrder });
	};

	render() {
		const { sortBy, sortOrder, currentPage } = this.state;

		let tableData = [...this.tabularData];
		tableData = this.filterByType(tableData);
		tableData = this.filterBySegment(tableData);
		tableData = sortTableData(tableData, sortBy, sortOrder);
		const totalPages = parseInt(tableData.length / this.USERS_PER_PAGE, 10) + 1;
		const firstIndex = currentPage * this.USERS_PER_PAGE;
		const lastIndex = Math.min(firstIndex + this.USERS_PER_PAGE, tableData.length);
		tableData = tableData.slice(firstIndex, lastIndex);

		tableData = prepareTableData(tableData);

		return (
			<Card className="main-card mb-3">
				<CardHeader>Active Users</CardHeader>

				<Table
					data={tableData}
					headers={[
						"User",
						"Type",
						"SEC",
						"Top Up",
						"SMS",
						"Calls",
						"Mobile Data",
						"User Score",
						"User Segment"
					]}
					keys={["id", "type", "sec", "recharge", "sms", "calls", "data", "score", "grade"]}
					sortBy={sortBy}
					sortOrder={sortOrder}
					onSort={this.handleSort}
				/>
				<CardFooter>
					<Row className="justify-content-end w-100 mx-0">
						<Pagination pageCount={totalPages} handlePageClick={this.handleChangePage} className="mb-0" />
					</Row>
				</CardFooter>
			</Card>
		);
	}
}

export default UsersTable;
