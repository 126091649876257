import React, { Component, Fragment } from "react";
import { Container, Card, CardHeader, Row, Col, CardFooter, CardBody } from "reactstrap";
import UsersTable from "./users_table";
import Map from "./map";
import Graphs from "./graphs";
import ScrollUpButton from "react-scroll-up-button";
import Timeline from "../../../components/Timeline";
import { Submissions } from "../utils/timeline_data";
import mapLegend from "../utils/mapLegendsData";
// import { RangeSlider } from "../../../components/Sliders/RangeSlider";

export default class JazzDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedVoronoi: null,
			selectedBox: null,
			legends: null
			// selectedFilter: null,
			// selectedTab: null
		};
	}

	handleSelectVoronoi = voronoiId => {
		if (voronoiId !== this.state.selectedVoronoi) this.setState({ selectedVoronoi: voronoiId, selectedBox: null });
		this.props.handleSelectedPolygon("voronoi", voronoiId);
	};

	handleSelectBox = boxId => {
		if (boxId !== this.state.selectedBox) this.setState({ selectedBox: boxId });
		this.props.handleSelectedPolygon("box", boxId);
	};

	// componentDidUpdate(prevProps) {
	// 	let { selectedFilter, selectedTab } = this.props;
	// 	if (selectedTab !== this.state.selectedTab && selectedTab !== prevProps.selectedTab)
	// 		this.setState({ selectedTab, selectedFilter: null });
	// 	else if (selectedFilter !== this.state.selectedFilter && selectedFilter !== prevProps.selectedFilter)
	// 		this.setState({ selectedFilter, selectedTab: null });
	// }
	renderMapLegends = () => {
		console.log("selectedMenu", this.props.selectedMenu);
		if (this.props.selectedMenu === "population") {
			return (
				<Col style={{ justifyContent: "center", alignItems: "center", position: "absolute", zIndex: 1000 }}>
					<h6 className="text-center mb-3">Population Legend</h6>
					{mapLegend.population.map((item, index) => (
						<span className={"mapLegends zoom ml-3"} style={{ color: item.color }}>
							<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
							{item.population}
						</span>
					))}
				</Col>
			);
		} else if (this.props.selectedMenu === "seclevel") {
			return (
				<Col style={{ justifyContent: "center", alignItems: "center" }}>
					<h6 className="text-center mb-3">SEC Level Legend</h6>
					{mapLegend.sec.map((item, index) => (
						<span className={"mapLegends zoom ml-3"} style={{ color: item.color }}>
							<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
							{item.population}
						</span>
					))}
				</Col>
			);
		}
	};

	render() {
		// console.log("selectedMenu: ", this.props.selectedMenu);
		const { selectedVoronoi, selectedBox } = this.state;
		const { selectedTypes, selectedSegments, selectedMenu, showAnalysis, selectedFilter, selectedTab } = this.props;
		return (
			<Fragment>
				<Container fluid>
					<Row style={{ marginTop: 20 }}>
						<Col lg="6">
							<Card>
								<Map
									selectedTypes={selectedTypes}
									selectedSegments={selectedSegments}
									handleSelectVoronoi={this.handleSelectVoronoi}
									handleSelectBox={this.handleSelectBox}
									selectedVoronoi={selectedVoronoi}
									selectedMenu={selectedMenu}
									selectedTab={this.props.selectedTab}
									selectedBox={selectedBox}
								/>
								{/* <CardHeader></CardHeader> */}
								{/* <CardBody>{this.renderMapLegends()}</CardBody> */}
							</Card>
						</Col>
						<Col sm="12" md="6" lg="6">
							<Card
								style={{
									// justifyContent: "center",
									alignContent: "center"
									// height: 690
								}}
							>
								{showAnalysis && (
									<Graphs
										selectedVoronoi={selectedVoronoi}
										selectedBox={selectedBox}
										selectedTab={selectedTab}
										selectedFilter={selectedFilter}
									/>
								)}
								{!showAnalysis && <Timeline data={Submissions.All} type="jazz" />}
							</Card>
						</Col>
					</Row>
					<Row className="mt-3">
						<Col md="12">
							<UsersTable selectedTypes={selectedTypes} selectedSegments={selectedSegments} />
						</Col>
					</Row>
				</Container>
				<ScrollUpButton />
			</Fragment>
		);
	}
}
