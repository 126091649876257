import React, { Fragment, Component } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClinicMedical, faClock, faUserCircle, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert-react";

class TimeLine extends Component {
	constructor(props) {
		super(props);
		this.state = {
			enumeratorModal: false,
			facilityModal: false,
			timeModal: false,
			show: false
		};
	}
	_toggleTimeModal = () => {
		this.setState({ timeModal: !this.state.timeModal });
	};
	render() {
		return (
			<Fragment>
				<Card className="main-card mb-1">
					<CardBody>
						<CardTitle>Live Submissions Timeline</CardTitle>
						<div style={{ height: this.props.type === "jazz" ? 520 : 545 }}>
							<PerfectScrollbar>
								<VerticalTimeline className="vertical-time-icons" layout="1-column">
									{this.props.data ? (
										this.props.data.map((item, index) => (
											<VerticalTimelineElement
												className="vertical-timeline-item"
												icon={
													<a
														href="javascript:void(0)"
														// className="timeline-icon border-success bg-success"
														onClick={() => {
															this.props.populateTable("Malir");
															this.props.setCardType("enumerator");
														}}
													>
														<img
															width={40}
															className="rounded-circle"
															src={item.profilePhoto}
															alt=""
														/>
													</a>
												}
											>
												<h4 className="timeline-title">{item.title}</h4>
												<a id="ServerSort" href="javascript:void(0)">
													<p
														className="text-warning"
														onClick={() => {
															this.props.populateTable("Korangi");
															this.props.setCardType("enumerator");
														}}
													>
														<i>
															<FontAwesomeIcon
																icon={faUserCircle}
																className="text-warning mr-2"
															/>
														</i>
														{item.name}
													</p>
												</a>
												<div className="row ml-1">
													<a href="javascript:()">
														<p
															className="text-danger"
															onClick={() => {
																this.props.populateTable("Thatta");
																this.props.setCardType("facility");
															}}
														>
															<i>
																<FontAwesomeIcon
																	icon={faClinicMedical}
																	className="text-danger mr-2"
																/>
															</i>
															{item.facilityCenter}
														</p>
													</a>
													<a id="ServerSort" href="javascript:void(0)">
														<p
															className="text-primary"
															onClick={() => this._toggleTimeModal()}
														>
															<i>
																<FontAwesomeIcon
																	icon={faClock}
																	className="text-primary ml-2 mr-2"
																/>
															</i>
															{item.dos}
														</p>
													</a>
												</div>
												{item.resubmission ? (
													<div className="pl-5">
														<FontAwesomeIcon
															icon={faAngleDoubleRight}
															style={{
																position: "absolute",
																left: 0,
																top: 110,
																fontSize: 30
															}}
															className="text-primary ml-2 mr-2"
														/>
														<VerticalTimelineElement
															className="vertical-timeline-item mt-2"
															icon={
																<a
																	href="javascript:void(0)"
																	// className="timeline-icon border-success bg-success"
																	onClick={() => {
																		this.props.populateTable("Malir");
																		this.props.setCardType("enumerator");
																	}}
																>
																	<img
																		width={40}
																		className="rounded-circle"
																		src={item.profilePhoto}
																		alt=""
																	/>
																</a>
															}
														>
															<h4 className="timeline-title">{"Re: " + item.title}</h4>
															<a href="javascript:void(0)">
																<p
																	className="text-dark"
																	onClick={() => {
																		this.props.populateTable("Hyderabad");
																		this.props.setCardType("enumerator");
																	}}
																>
																	<i>
																		<FontAwesomeIcon
																			icon={faUserCircle}
																			className="text-dark mr-2"
																		/>
																	</i>
																	{item.name}
																</p>
															</a>
															<div className="row ml-1">
																<a href="javascript:void(0)">
																	<p
																		className="text-dark"
																		onClick={() => {
																			this.props.populateTable("Tharparkar");
																			this.props.setCardType("facility");
																		}}
																	>
																		<i>
																			<FontAwesomeIcon
																				icon={faClinicMedical}
																				className="text-dark mr-2"
																			/>
																		</i>
																		{item.facilityCenter}
																	</p>
																</a>
																<a href="javascript:void(0)">
																	<p
																		className="text-dark"
																		onClick={() => this._toggleTimeModal()}
																	>
																		<i>
																			<FontAwesomeIcon
																				icon={faClock}
																				className="text-dark ml-2 mr-2"
																			/>
																		</i>
																		{item.dos}
																	</p>
																</a>
															</div>
														</VerticalTimelineElement>
													</div>
												) : null}
												<Button
													className="btn btn-sm btn-warning text-white"
													style={{
														justifyContent: "flex-end",
														position: "absolute",
														right: 30,
														top: 30
													}}
													onClick={() => this.setState({ show: true })}
												>
													{/* <a
														href="https://app.surveyauto.com"
														target="blank"
														className="text-white"
													>
														
													</a> */}
													Recheck
													<SweetAlert
														title="Successful!"
														confirmButtonColor=""
														show={this.state.show}
														text="Your Recheck has been scheduled!"
														type="success"
														onConfirm={() => this.setState({ show: false })}
													/>
												</Button>
											</VerticalTimelineElement>
										))
									) : (
										<div className="text-center">
											<h4>No Submissions</h4>
										</div>
									)}
								</VerticalTimeline>
							</PerfectScrollbar>
						</div>
					</CardBody>
				</Card>
			</Fragment>
		);
	}
}

export default TimeLine;
