import React, { Component, Fragment } from "react";
import Chart from "react-apexcharts";
import JazzData from "../data/jazz-data.json";
import TabData from "../data/tabular_data.json";
import { CardHeader } from "reactstrap";

const MONTH_COUNT = 6;
const marginTop = 0;
class Graphs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			graphTitle: "market share",
			series: [76, 67, 95],
			options: {
				chart: {
					id: "survey-analysis-chart"
					// height: 390,
					// type: "radialBar",
					// sparkline: {
					// 	enabled: true
					// }
				},
				dataLabels: {
					enabled: false
				},
				// colors: ["#FF6939", "#f0027f", "#CFCF00", "#7fc97f", "#beaed4", "#fdc086", "#386cb0"],
				// colors: ["#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7"],
				labels: ["> 2 km", "1 - 2 km", "< 1 km"],
				// labels: ["Oct", "Nov", "Dec", "Jan", "Feb", "Mar"],
				legend: {
					show: false,
					inverseOrder: true,
					// floating: true,
					fontSize: "14px",
					horizontalAlign: "center",
					position: "bottom",
					// offsetX: 160,
					// offsetY: 15,
					labels: {
						useSeriesColors: true
					},
					markers: {
						size: 0
					},
					formatter: function(seriesName, opts) {
						return ["\n\r", seriesName, ":", "\n\r", opts.w.globals.series[opts.seriesIndex]];
					},
					itemMargin: {
						vertical: 3
					}
				},
				plotOptions: {
					bar: {
						horizontal: false
					},
					radialBar: {
						endAngle: 270,
						hollow: {
							margin: 5,
							size: "30%",
							background: "transparent"
						},
						dataLabels: {
							show: true,
							name: {
								show: true
							},
							value: {
								show: true,
								formatter: function(val) {
									return Math.round(val * 100) / 100 + "%";
								}
							},
							total: {
								show: true,
								formatter: function(w) {
									return (
										Math.round(
											(w.globals.seriesTotals.reduce((a, b) => {
												return a + b;
											}, 0) /
												w.globals.series.length) *
												100
										) /
											100 +
										"%"
									);
								}
							}
						}
					}
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								show: true
							}
						}
					}
				],
				theme: {
					mode: "light",
					palette: "palette5"
				},
				// title: {
				// 	text: ["LAHORE"],
				// 	align: "center",
				// 	style: {
				// 		fontSize: "16px",
				// 		fontWeight: 300,
				// 		margin: 20
				// 	}
				// },
				xaxis: {
					categories: ["Oct", "Nov", "Dec", "Jan", "Feb", "Mar"]
				},
				yaxis: {
					show: true,
					title: {
						text: "",
						style: {
							fontSize: "14px",
							fontFamily: "Arial",
							fontWeight: 600,
							cssClass: "apexcharts-yaxis-title"
						}
					}
				}
			},
			selectedTab: "",
			selectedVoronoi: "",
			selectedBox: "",
			graph: null,
			marginTop: marginTop,
			leftMenu: null
		};
		this.tabIdToAttribute = {
			0: "market share",
			1: "revenue potential",
			2: "access",
			null: null
		};
		this.attributeToType = {
			"market share": "line",
			"revenue potential": "line",
			access: "radialBar",
			null: "bar"
		};
		this.typeToLeftMenu = {
			type: "type",
			segment: "grade",
			null: null
		};
	}

	componentWillMount() {
		this.switchGraph();
	}

	componentDidUpdate() {
		let { selectedTab, selectedVoronoi, selectedBox, selectedFilter } = this.props;
		if (selectedTab !== null && this.tabIdToAttribute[selectedTab] !== this.state.selectedTab) {
			this.setState({ selectedTab: this.tabIdToAttribute[selectedTab], graph: null }, this.switchGraph);
		} else if (selectedVoronoi !== this.state.selectedVoronoi) {
			this.setState({ selectedVoronoi: selectedVoronoi, graph: null }, this.switchGraph);
		} else if (selectedBox && selectedBox !== this.state.selectedBox) {
			this.setState({ selectedBox: selectedBox, graph: null }, this.switchGraphForBox);
		} else if (selectedFilter && this.typeToLeftMenu[selectedFilter] !== this.state.leftMenu) {
			this.setState({ leftMenu: this.typeToLeftMenu[selectedFilter], graph: null }, this.prepareDataForLeftMenu);
		}
	}

	switchGraph = () => {
		try {
			let { selectedTab, selectedVoronoi, series, options, graph, graphTitle } = this.state;
			let data = {};
			if (!selectedVoronoi) {
				selectedVoronoi = "LAHORE";
				data = JazzData[selectedVoronoi].data;
			} else data = JazzData["LAHORE"].locations[selectedVoronoi].data;
			if (!selectedTab) selectedTab = this.tabIdToAttribute[0];
			let type = this.attributeToType[selectedTab];
			graphTitle = selectedTab + ": " + selectedVoronoi;
			// options.yaxis.min = null;
			// options.yaxis.max = null;
			options.dataLabels.enabled = false;
			options.dataLabels.enabledOnSeries = [];
			options.legend.show = false;
			options.legend.formatter = function(seriesName, opts) {
				return ["\n\r", seriesName, ":", "\n\r", opts.w.globals.series[opts.seriesIndex]];
			};
			// options.title.text[0] = selectedVoronoi;
			// options.title.text[1] = selectedTab;
			options.yaxis.title.text = selectedTab;
			if (selectedTab === "market share") {
				series = [
					{ name: "Market Share", type: "column", data: data[selectedTab] },
					{ name: "Market Share", type: "line", data: data[selectedTab] }
				];
				// options.yaxis.min = 0;
				// options.yaxis.max = 100;
				options.dataLabels.enabled = true;
				options.dataLabels.enabledOnSeries = [1];
				options.legend.formatter = function(seriesName, opts) {
					return ["\n\r", seriesName, ":", "\n\r", opts.w.globals.series[opts.seriesIndex], "%"];
				};
			} else if (selectedTab === "revenue potential") {
				series = [
					{ name: "Revenue Potential", type: "column", data: data[selectedTab] },
					{ name: "Revenue Potential", type: "line", data: data[selectedTab] }
				];
				// options.yaxis.min = Math.min(data[selectedTab]) - 1;
				// options.yaxis.max = Math.max(data[selectedTab]) + 1;
				options.dataLabels.enabled = true;
				options.dataLabels.enabledOnSeries = [1];
			} else {
				series = data[selectedTab];
				options.labels = ["> 2 km", "1 - 2 km", "< 1 km"];
				options.legend.show = true;
				options.legend.formatter = function(seriesName, opts) {
					return ["\n\r", seriesName, ":", "\n\r", opts.w.globals.series[opts.seriesIndex], "%"];
				};
			}
			graph = <Chart options={options} series={series} type={type} height="150%" />;
			this.setState({
				graph,
				series,
				options,
				graphTitle,
				marginTop: marginTop
			});
			// this.setState({ graph: null, series, options, graphTitle, marginTop: marginTop }, () => {
			// 	this.setMultipleCharts(type);
			// });
		} catch (e) {
			console.error(e);
		}
	};

	switchGraphForBox = () => {
		try {
			let { selectedTab, selectedBox, series, options, graph, graphTitle } = this.state;
			let data = [];
			if (!selectedTab) selectedTab = this.tabIdToAttribute[0];
			let type = this.attributeToType[selectedTab];
			graphTitle = selectedTab + ": " + selectedBox;

			options.yaxis.min = null;
			options.yaxis.max = null;
			options.dataLabels.enabled = false;
			options.dataLabels.enabledOnSeries = [];
			options.legend.show = false;
			// options.title.text[0] = selectedVoronoi;
			// options.title.text[1] = selectedTab;
			options.yaxis.title.text = selectedTab;
			if (selectedTab !== "access") {
				for (let i = 0; i < MONTH_COUNT; i++) {
					data.push(Math.floor(Math.random() * (40 - 15 + 1) + 15));
				}
				if (selectedTab === "market share") {
					series = [
						{ name: "Market Share", type: "column", data: data },
						{ name: "Market Share", type: "line", data: data }
					];
					options.yaxis.min = 0;
					options.yaxis.max = 100;
					options.dataLabels.enabled = true;
					options.dataLabels.enabledOnSeries = [1];
				} else if (selectedTab === "revenue potential") {
					series = [{ name: "Revenue Potential", data: data }];
				}
			} else {
				let n1 = Math.random();
				let n2 = Math.random();
				let n3 = Math.random();
				let ratio = 1 / (n1 + n2 + n3);
				n1 = n1 * ratio;
				n2 = n2 * ratio;
				n3 = n3 * ratio;
				data = [Math.floor(n1 * 100), Math.floor(n2 * 100), Math.floor(n3 * 100)];
				series = data;
				options.labels = ["> 2 km", "1 - 2 km", "< 1 km"];
				options.legend.show = true;
			}
			graph = <Chart options={options} series={series} type={type} height="150%" />;
			this.setState({ graph, series, options, graphTitle });
		} catch (e) {
			console.error(e);
		}
	};

	setMultipleCharts = type => {
		let { series, options } = this.state;
		let graph = (
			<div>
				{[1, 2, 3].map(row => {
					return (
						<div className="row" key={`row_${row}`}>
							{[1, 2].map(col => {
								return (
									<div className="col col-6" key={`col_${row}_${col}`}>
										<Chart options={options} series={series} type={type} height="100%" />
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		);
		this.setState({ graph, marginTop: 1 });
	};

	prepareDataForLeftMenu = () => {
		try {
			let { leftMenu, graphTitle, selectedVoronoi } = this.state;
			let series = [];
			let options = {
				// colors: ["#FF6939", "#f0027f", "#CFCF00", "#7fc97f", "#beaed4", "#fdc086", "#386cb0"],
				colors: ["#2B908F", "#F9A3A4", "#90EE7E", "#69D2E7", "#FA4443"],
				labels: [],
				legend: {
					show: true,
					inverseOrder: false,
					fontSize: "14px",
					horizontalAlign: "center",
					position: "bottom",
					labels: {
						useSeriesColors: true
					},
					markers: {
						size: 0
					},
					formatter: function(seriesName, opts) {
						return ["\n\r", seriesName, ":", "\n\r", opts.w.globals.series[opts.seriesIndex]];
					},
					itemMargin: {
						vertical: 3
					}
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								show: true
							}
						}
					}
				]
				// theme: {
				// 	mode: "light",
				// 	palette: "palette5"
				// }
			};
			let counts = {};
			if (leftMenu === "type") {
				graphTitle = "User Type";
				counts = {
					STUDENT: 0,
					RESIDENTIAL: 0,
					COMMERCIAL: 0
				};
			} else if (leftMenu === "grade") {
				graphTitle = "User Segment";
				counts = {
					A: 0,
					B: 0,
					C: 0,
					D: 0
					// D: 0
				};
			} else {
			}
			if (selectedVoronoi) {
				for (const data of TabData) {
					if (data.polygon === selectedVoronoi) counts[data[leftMenu].toUpperCase()] += 1;
				}
			} else {
				for (const data of TabData) {
					counts[data[leftMenu].toUpperCase()] += 1;
				}
			}
			for (const key of Object.keys(counts)) {
				series.push(counts[key]);
				options.labels.push(key);
			}
			let graph = <Chart options={options} series={series} type="pie" height="150%" />;
			this.setState({ graph, graphTitle });
		} catch (e) {
			console.error(e);
		}
	};

	render() {
		return (
			<div className="container text-center">
				<div style={{ height: "60%" }}>
					<CardHeader>{this.state.graphTitle}</CardHeader>
					<div
						style={{
							height: "100%",
							marginTop: this.state.marginTop
						}}
					>
						{this.state.graph}
					</div>
				</div>
			</div>
		);
	}
}

export default Graphs;
