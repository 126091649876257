import React, { Fragment, Component } from "react";
import { ComposableMap, ZoomableGroup, Geographies, Geography } from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import { Motion, spring } from "react-motion";
import { Button, Row, CardHeader, CardBody } from "reactstrap";
// import world from "../../utils/static/world-50m-with-population";
import pakistanDistricts from "../../utils/static/pakistan-districts";

const popScale = scaleLinear()
	.domain([0, 100000000, 1400000000])
	.range(["#CFD8DC", "#adb5bd", "#37474F"]);

const districts = [
	{ name: "Malir", color: "#b3cde0", id: 0 },
	{ name: "Korangi", color: "#83d0c9", id: 0 },
	{ name: "Thatta", color: "#e3c9c9", id: 0 },
	{ name: "Shaheed Benazirabad", color: "#ff8b94", id: 0 },
	{ name: "Larkana", color: "#8c9da9", id: 0 },
	{ name: "Hyderabad", color: "#8874a3", id: 0 },
	{ name: "Tharparkar", color: "#f1c27d", id: 0 }
];

export class DistrictMaps extends Component {
	constructor() {
		super();
		this.state = {
			center: [0, 10],
			zoom: 2,
			name: ""
		};
		this.handleZoomIn = this.handleZoomIn.bind(this);
		this.handleZoomOut = this.handleZoomOut.bind(this);
		this.handleCityClick = this.handleCityClick.bind(this);
		this.handleReset = this.handleReset.bind(this);
	}

	handleZoomIn() {
		this.setState({
			zoom: this.state.zoom * 2
		});
	}

	handleZoomOut() {
		this.setState({
			zoom: this.state.zoom / 2
		});
	}

	handleCityClick(city) {
		this.setState({
			zoom: 2,
			center: city.coordinates
		});
	}

	handleReset() {
		this.setState({
			center: [0, 10],
			zoom: 2
		});
	}

	render() {
		return (
			<Fragment>
				<div>
					<div className="text-right mb-2 mt-3 mr-3">
						<Button
							color="outline-focus"
							className="mr-2 rounded-circle btnSquare-sm"
							onClick={this.handleZoomIn}
						>
							<span style={{ marginTop: "-4px" }}>&#x0002B;</span>
						</Button>
						<Button
							color="outline-focus"
							className="mr-2 rounded-circle btnSquare-sm"
							onClick={this.handleZoomOut}
						>
							<span style={{ marginTop: "-4px" }}>&minus;</span>
						</Button>
						<Button
							color="outline-focus"
							className="rounded-circle btnSquare-sm"
							onClick={this.handleReset}
						>
							<i className="pe-7s-refresh-2"></i>
						</Button>
					</div>
					<Motion
						defaultStyle={{
							zoom: 1,
							x: 0,
							y: 20
						}}
						style={{
							zoom: spring(this.state.zoom, { stiffness: 210, damping: 20 }),
							x: spring(this.state.center[0], { stiffness: 210, damping: 20 }),
							y: spring(this.state.center[1], { stiffness: 210, damping: 20 })
						}}
					>
						{({ zoom, x, y }) => (
							// <div style={{ overflow: "hidden", maxHeight: 400 }}>
							<div style={{ overflow: "hidden" }}>
								<ComposableMap
									projectionConfig={{
										scale: 250,
										rotation: [0, 0, 0]
									}}
									// width={480}
									// height={560}
									style={{
										// width: "auto"
										height: "auto"
									}}
								>
									<ZoomableGroup center={[x, y]} zoom={zoom}>
										<Geographies geography={pakistanDistricts} disableOptimization>
											{(geographies, projection) =>
												geographies.map((geography, i) => (
													<Geography
														key={`${geography.properties.ID_0}-${i}`}
														id="tooltip"
														cacheId={`${geography.properties.ID_0}-${i}`}
														geography={geography}
														projection={projection}
														onClick={data => {
															this.props.toggleDistrictMap(data.properties.NAME_3);
															this.props.getDistrictIndex(data.properties.NAME_3);
														}}
														onMouseEnter={data => {
															this.setState({ name: data.properties.NAME_3 });
														}}
														round
														style={{
															default: {
																fill: geography.properties.color
																	? geography.properties.color
																	: popScale(geography.properties.ID_0),
																stroke: "#adb5bd",
																strokeWidth: 0.75,
																outline: "none"
															},
															hover: {
																fill: "#263238",
																stroke: "#adb5bd",
																strokeWidth: 0.75,
																outline: "none"
															},
															pressed: {
																fill: "#263238",
																stroke: "#adb5bd",
																strokeWidth: 0.75,
																outline: "none"
															}
														}}
													/>
												))
											}
										</Geographies>
									</ZoomableGroup>
								</ComposableMap>
							</div>
						)}
					</Motion>
					<CardHeader>Districts</CardHeader>
					<CardBody>
						<Row>
							{districts.map((item, index) => (
								<span
									className={item.name === this.state.name ? "mapLegends zoom" : "mapLegends"}
									style={{ color: item.color }}
								>
									<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
									{item.name}
								</span>
							))}
						</Row>
					</CardBody>
				</div>
			</Fragment>
		);
	}
}
