// import React from 'react'
// import FusionCharts from "fusioncharts";
// import charts from "fusioncharts/fusioncharts.charts";
// import ReactFusioncharts from "react-fusioncharts";

// // Resolves charts dependancy
// charts(FusionCharts);

// const dataSource = {
//     chart: {
//         // caption: "Avocado sales among different states",
//         // subcaption: "2016-2018",
//         // plottooltext: "<b>$seriesName</b><hr>$label: <b>$dataValue</b>",
//         // xaxisname: "Quarter",
//         // yaxisname: "Sales (In USD)",
//         theme: "candy",
//         numvisibleplot: 7
//     },
//     categories: [
//         {
//             category: [
//                 {label: "Dec"},
//                 {label: "Jan"},
//                 {label: "Feb"},
//                 {label: "Mar"},
//                 {label: "Apr"},
//             ]
//             // category: [
//             //     {label: "2015-Q1"},
//             //     {label: "2015-Q2"},
//             //     {label: "2015-Q3"},
//             //     {label: "2015-Q4"},
//             //     {label: "2016-Q1"},
//             //     {label: "2016-Q2"},
//             //     {label: "2016-Q3"},
//             //     {label: "2016-Q4"},
//             //     {label: "2017-Q1"},
//             //     {label: "2017-Q2"},
//             //     {label: "2017-Q3"},
//             //     {label: "2017-Q4"}
//             // ]
//         }
//     ],
//     dataset: [
//         {
//             dataset: [
//                 {
//                     seriesname: "New York",
//                     data: [
//                         {value: "510344.4"},
//                         {value: "825541.86"},
//                         {value: "1078603.31"},
//                         {value: "895751.26"},
//                         {value: "1213115.71"},
//                         {value: "1207901.26"},
//                         {value: "1163397.45"},
//                         {value: "841621.26"},
//                         {value: "1245085.8"},
//                         {value: "1103347.9"},
//                         {value: "1022076.68"},
//                         {value: "1025565.8"}
//                     ]},
//                 {
//                     seriesname: "Georgia",
//                     data: [
//                         {value: "5318642.2"},
//                         {value: "5701956"},
//                         {value: "5095298.61"},
//                         {value: "4118810.44"},
//                         {value: "5240363.94"},
//                         {value: "5212220.71"},
//                         {value: "4522632.08"},
//                         {value: "3374924.99"},
//                         {value: "4764169.41"},
//                         {value: "4424022.98"},
//                         {value: "3989718.21"},
//                         {value: "3970043.21"}
//                     ]}
//             ]
//         },
//         {
//             dataset: [
//                 {
//                     seriesname: "Maryland",
//                     data: [
//                         {value: "7524659.79"},
//                         {value: "8196424.21"},
//                         {value: "8325170.33"},
//                         {value: "7111010.55"},
//                         {value: "8694218.26"},
//                         {value: "8895865.95"},
//                         {value: "7244663.98"},
//                         {value: "5703274.8"},
//                         {value: "8419998.48"},
//                         {value: "7388812.3"},
//                         {value: "6517872.07"},
//                         {value: "7064818.95"}
//                     ]},
//                 {
//                     seriesname: "Idaho",
//                     data: [
//                         {value: "887124.05"},
//                         {value: "993801.32"},
//                         {value: "803841.86"},
//                         {value: "581555.6"},
//                         {value: "696292.47"},
//                         {value: "577209.67"},
//                         {value: "563216.54"},
//                         {value: "520417.15"},
//                         {value: "688505.86"},
//                         {value: "718619.31"},
//                         {value: "630249.87"},
//                         {value: "628688"}
//                     ]},
//                 {
//                     seriesname: "Massachusetts",
//                     data: [
//                         {value: "4441861.75"},
//                         {value: "5404269.51"},
//                         {value: "6223966.11"},
//                         {value: "5626179.46"},
//                         {value: "6722592.97"},
//                         {value: "6931022.03"},
//                         {value: "5220110.38"},
//                         {value: "4765293.29"},
//                         {value: "6584454.42"},
//                         {value: "5961664.26"},
//                         {value: "5567418.89"},
//                         {value: "5466678.99"}
//                     ]}
//             ]
//         }
//     ]
// };
import React, { Component } from "react";
import CanvasJSReact from "../../assets/js/canvasjs/canvasjs.react";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const dataSource = {
	title: {
		text: "Grouped Stacked Chart"
	},
	theme: "theme3",
	axisY: {
		maximum: 300
	},
	axisY2: {
		maximum: 300,
		lineThickness: 0,
		tickLength: 0,
		labelFormatter: function(e) {
			return "";
		}
	},
	legend: {
		verticalAlign: "center",
		horizontalAlign: "right"
	},

	data: [
		{
			type: "stackedColumn",
			showInLegend: true,
			name: "Malir Total",
			dataPoints: [
				{ x: 1, y: 44, label: "Dec" },
				{ x: 8, y: 55, label: "Jan" },
				{ x: 15, y: 57, label: "Feb" },
				{ x: 22, y: 56, label: "Mar" },
				{ x: 29, y: 61, label: "Apr" }
			]
		},
		{
			type: "stackedColumn",
			showInLegend: true,
			name: "Malir Stock Out",
			dataPoints: [
				{ x: 1, y: 4, label: "Dec" },
				{ x: 8, y: 5, label: "Jan" },
				{ x: 15, y: 7, label: "Feb" },
				{ x: 22, y: 6, label: "Mar" },
				{ x: 29, y: 1, label: "Apr" }
			]
		},
		{
			type: "stackedColumn",
			showInLegend: true,
			name: "Korangi Total",
			dataPoints: [
				{ x: 2, y: 44, label: "Dec" },
				{ x: 9, y: 55, label: "Jan" },
				{ x: 16, y: 57, label: "Feb" },
				{ x: 23, y: 56, label: "Mar" },
				{ x: 30, y: 61, label: "Apr" }
			]
		},
		{
			type: "stackedColumn",
			showInLegend: true,
			name: "Korangi Stock Out",
			dataPoints: [
				{ x: 2, y: 4, label: "Dec" },
				{ x: 9, y: 5, label: "Jan" },
				{ x: 16, y: 7, label: "Feb" },
				{ x: 23, y: 6, label: "Mar" },
				{ x: 30, y: 1, label: "Apr" }
			]
		}
	]
};
class GroupedStackedColumn extends Component {
	render() {
		return (
			<div className="bar">
				<CanvasJSChart options={dataSource} />
			</div>
		);
	}
}

export default GroupedStackedColumn;
