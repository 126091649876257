import React, { Fragment } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import HeaderLogo from "../AppLogo/index";
import SearchBox from "./Components/SearchBox";
import MegaMenu from "./Components/MegaMenu";
import UserBox from "./Components/UserBox";
import HeaderDots from "./Components/HeaderDots";
import { Button } from "reactstrap";
import SALogo from "../../assets/images/logo.svg";
class Header extends React.Component {
	render() {
		let {
			headerBackgroundColor,
			enableMobileMenuSmall,
			enableHeaderShadow,
			showProductsDropdown,
			showDateSelector,
			handleMicroSurveyClick
		} = this.props;
		return (
			<Fragment>
				<CSSTransitionGroup
					component="div"
					className={cx("app-header", headerBackgroundColor, {
						"header-shadow": enableHeaderShadow
					})}
					transitionName="HeaderAnimation"
					transitionAppear={true}
					transitionAppearTimeout={1500}
					transitionEnter={false}
					transitionLeave={false}
				>
					<HeaderLogo isToggleOn={this.props.top.isToggleOn} toggleSwitch={this.props.toggleSwitch} />
					<div className="logo">
						<a href="/" className="">
							<img src={SALogo} />
						</a>
					</div>
					<div
						className={cx("app-header__content", {
							"header-mobile-open": enableMobileMenuSmall
						})}
					>
						{showProductsDropdown && (
							<div className="dropdownSelect">
								<SearchBox top={this.props.top} onProductChange={this.props.onProductChange} />
							</div>
						)}
						<div className="desktopShown textEllipsis">
							<MegaMenu
								menuItems={this.props.menuItems}
								top={this.props.top.topTabs}
								onChangeTopTabs={this.props.onChangeTopTabs}
							/>
						</div>
						{/* <a href="#" target="blank" className="text-white ml-3">
							<Button
								className="btn-small btn-primary text-white ml-3"
								disabled={this.props.disabled}
								onClick={handleMicroSurveyClick}
							>
								<i className="fas fa-tasks" />
								<span className="ml-2">Micro Survey</span>
							</Button>
						</a> */}
						{/* <form
							method="post"
							action={process.env.REACT_APP_SURVEY_AUTO_COMPOSER_FRONTEND}
							target="_blank"
						>
							<input type="hidden" name="markerList" value="ABC" />
							<input
								type="submit"
								className="btn-small btn-primary text-white ml-3"
								// disabled={this.props.disabled}
							/>
							<i className="fas fa-tasks" />
								<span className="ml-2">Micro Survey</span>
							</input>
						</form> */}
						<a
							href="https://healthmonitor.surveyauto.com/"
							target="_blank"
							className="btn btn-small btn-primary ml-2"
						>
							Surveillance
						</a>
						<Button
							className="btn-small btn-primary text-white ml-2"
							disabled={this.props.disabled}
							onClick={handleMicroSurveyClick}
						>
							Survey
						</Button>
						<div className="app-header-left ml-auto">
							<div className="app-header-right">
								<HeaderDots
									top={this.props.top}
									toggleSwitch={this.props.toggleSwitch}
									changeDates={this.props.changeDates}
									showDateSelector={showDateSelector}
								/>
								<UserBox />
								{/* <HeaderRightDrawer /> */}
							</div>
						</div>
					</div>
					<div className="essentialMenuBar">
						<MegaMenu
							menuItems={this.props.menuItems}
							top={this.props.top.topTabs}
							onChangeTopTabs={this.props.onChangeTopTabs}
						/>
						<a
							href="https://healthmonitor.surveyauto.com/"
							target="_blank"
							className="btn btn-small btn-primary ml-2"
						>
							Surveillance
						</a>
						<Button
							className="btn-small btn-primary text-white ml-2"
							disabled={this.props.disabled}
							onClick={handleMicroSurveyClick}
						>
							Survey
						</Button>
					</div>
				</CSSTransitionGroup>
			</Fragment>
		);
	}
}
Header.defaultProps = {
	showProductsDropdown: true,
	showDateSelector: true
};
const mapStateToProps = state => ({
	enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
	closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
	headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
	enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
