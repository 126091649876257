import React from "react";
import { orderBy } from "lodash";

const gradeClass = {
	A: "success",
	B: "info",
	C: "warning",
	D: "danger"
};

const typeClass = {
	COMMERCIAL: "success",
	RESIDENTIAL: "info",
	STUDENT: "secondary"
};

const secClass = {
	A: "success",
	B: "info",
	C: "danger"
};

const prepareTableData = data => {
	let newData = data.map(row => {
		let grade = row["grade"];
		grade = (
			<span
				style={{ fontWeight: "bold", fontSize: 14 }}
				className={`badge badge-${gradeClass[grade]} badge-pill`}
			>
				{grade}
			</span>
		);
		let sec = row["sec"];
		sec = (
			<span style={{ fontWeight: "bold", fontSize: 14 }} className={`badge badge-${secClass[sec]} badge-pill`}>
				{sec}
			</span>
		);
		let type = row["type"];
		type = (
			<span style={{ fontWeight: "bold" }} className={`badge badge-${typeClass[type]} badge-pill`}>
				{type}
			</span>
		);
		const id = parseInt(row["id"], 10);
		const score = parseInt(row.score * 100, 10)
			.toString()
			.slice(0, 4);
		return { ...row, grade, score, type, sec, id };
	});
	return newData;
};

const sortTableData = (data, sortBy, sortOrder = "asc") => {
	const orderdData = orderBy(data, sortBy, sortOrder);
	return orderdData;
};

export { prepareTableData, sortTableData };
