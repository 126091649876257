import React, { Component } from "react";
import Chart from "react-apexcharts";

class BasicLine extends Component {
	constructor(props) {
		super(props);

		this.state = {
			series: [
				{
					name: "Avalibility",
					data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
				}
			],
			options: {
				chart: {
					height: 350,
					type: "line",
					zoom: {
						enabled: false
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: "straight"
				},
				// title: {
				//   text: 'Product Trends by Month',
				//   align: 'left'
				// },
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5
					}
				},
				xaxis: {
					categories: ["100", "200", "300", "400", "500", "600", "700", "800", "900"]
				}
			}
		};
	}

	render() {
		return (
			<div className="bar">
				<Chart options={this.state.options} series={this.state.series} type="line" height={605} />
			</div>
		);
	}
}

export default BasicLine;
