import React, { Component } from "react";
import Chart from "react-apexcharts";
let colors = ["#b3cde0", "#83d0c9", "#e3c9c9", "#ff8b94", "#8c9da9", "#8874a3", "#f1c27d"];
class Scatter extends Component {
	constructor(props) {
		super(props);

		this.options = {
			colors: colors,
			chart: {
				type: "line",
				stacked: false
			},
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
					horizontal: false
				}
			},
			xaxis: {
				categories: ["Dec", "Jan", "Feb", "Mar", "Apr"]
			},
			yaxis: {
				show: true,
				title: {
					text: "",
					style: {
						fontSize: "14px",
						fontFamily: "Arial",
						fontWeight: 600,
						cssClass: "apexcharts-yaxis-title"
					}
				}
			}
		};

		this.series = [
			{
				name: "Malir",
				data: [44, 55, 57, 56, 61]
			},
			{
				name: "Korangi",
				data: [76, 85, 101, 98, 87]
			},
			{
				name: "Thatta",
				data: [35, 41, 36, 26, 45]
			},
			{
				name: "Shaheed Benazirabad",
				data: [58, 63, 60, 66, 105]
			},
			{
				name: "Larkana",
				data: [91, 114, 94, 35, 41]
			},
			{
				name: "Hyderabad",
				data: [48, 52, 53, 41, 101]
			},
			{
				name: "Tharparkar",
				data: [66, 44, 33, 22, 11]
			}
		];
	}

	render() {
		let data = this.props.data ? this.props.data : this.series;
		let options = this.options;
		if (this.props.xAxis) options.xaxis.categories = this.props.xAxis;
		if (this.props.yLabel) options.yaxis.title.text = this.props.yLabel;
		if (this.props.colors) options.colors = this.props.colors;
		return (
			// <div className="bar">
			<Chart options={options} series={data} type="scatter" width="100%" />
			// </div>
		);
	}
}

export default Scatter;
