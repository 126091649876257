import React from "react";
import Slider from "rc-slider";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const marks = {
	0: {
		style: {
			color: "white"
		},
		label: <strong>0</strong>
	},
	10: {
		style: {
			color: "white"
		},
		label: <strong>10</strong>
	},
	20: {
		style: {
			color: "white"
		},
		label: <strong>20</strong>
	},
	30: {
		style: {
			color: "white"
		},
		label: <strong>30</strong>
	},
	40: {
		style: {
			color: "white"
		},
		label: <strong>40</strong>
	},
	50: {
		style: {
			color: "white"
		},
		label: <strong>50</strong>
	},
	60: {
		style: {
			color: "white"
		},
		label: <strong>60</strong>
	},
	70: {
		style: {
			color: "white"
		},
		label: <strong>70</strong>
	},
	80: {
		style: {
			color: "white"
		},
		label: <strong>80</strong>
	},
	90: {
		style: {
			color: "white"
		},
		label: <strong>90</strong>
	},
	100: {
		style: {
			color: "white"
		},
		label: <strong>100</strong>
	}
};

const marks2 = {
	0: {
		style: {
			color: "white"
		},
		label: <strong>0%</strong>
	},
	10: {
		style: {
			color: "white"
		},
		label: <strong>10%</strong>
	},
	20: {
		style: {
			color: "white"
		},
		label: <strong>20%</strong>
	},
	30: {
		style: {
			color: "white"
		},
		label: <strong>30%</strong>
	},
	40: {
		style: {
			color: "white"
		},
		label: <strong>40%</strong>
	},
	50: {
		style: {
			color: "white"
		},
		label: <strong>50%</strong>
	},
	60: {
		style: {
			color: "white"
		},
		label: <strong>60%</strong>
	},
	70: {
		style: {
			color: "white"
		},
		label: <strong>70%</strong>
	},
	80: {
		style: {
			color: "white"
		},
		label: <strong>80%</strong>
	},
	90: {
		style: {
			color: "white"
		},
		label: <strong>90%</strong>
	},
	100: {
		style: {
			color: "white"
		},
		label: <strong>100%</strong>
	}
};

export const RangeSlider = ({ min, max, defaultValue, onSlide, count, dots, selectedTab, step }) => {
	let marks = {};
	let delta = (max - min) / count;
	for (let i = 0; i < count; i++) {
		let value = min + delta * i;
		marks[value] = {
			style: {
				color: "black"
			},
			label: <strong>{Math.round((value + Number.EPSILON) * 100) / 100}</strong>
		};
	}
	marks[max] = {
		style: {
			color: "black"
		},
		label: <strong>{max}</strong>
	};
	return (
		<Range
			min={min}
			max={max}
			defaultValue={defaultValue}
			className="rc-slider-primary rc-slider-lg mb-2"
			onChange={value => onSlide(value)}
			// count={count}
			tipFormatter={value => (selectedTab === 0 ? `${value}%` : value)}
			// marks={selectedTab === 0 ? marks2 : marks}
			marks={marks}
			dots={dots}
			step={step}
		/>
	);
};
