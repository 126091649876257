import React, { Component } from "react";
import { Container, Card, Row, Col, Collapse, CardBody, CardHeader } from "reactstrap";
import { DistrictMaps } from "../../../components/DistrictMaps";
import BasicLine from "../../../components/Graphs/BasicLine";
import Bar from "../../../components/Graphs/Bar";
import StackedArea from "../../../components/Graphs/StackedArea";
import StackedColumn from "../../../components/Graphs/StackedColumn";
import Scatter from "../../../components/Graphs/Scatter";
import MultiSeriesRadar from "../../../components/Graphs/MultiSeriesRadar";
import GroupedStackedColumn from "../../../components/Graphs/GroupedStackedColumn";
import { GoogleMaps } from "../../../components";
import allData from "../../../utils/static/total-data-min.json";
import { TimelineTable } from "../../../components/Table/TimelineTable";
import { Submissions } from "../../../utils/static/districtSubmssions";
import { RadarChart } from "recharts";
import GraphComponent from "../GraphComponent";
import TimeLine from "../../../components/Timeline";
import user from "../../../assets/images/user.jpg";
import medical from "../../../assets/images/medical.jpg";
class Analytics2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timelineData: Submissions.All,
			// districtIndex: null,
			// tableData: Submissions.All,
			cardType: "",
			collapse: true,
			allData: {},
			isDistrictMap: true,
			selectedData: {},
			googleMapCenter: {},
			googleMapZoom: 7,
			districtIndex: null,
			tableData: Submissions.All,
			graph: null,
			topTab: "",
			leftTab: "",
			topAttribute: null,
			leftAttribute: null,
			lastClickedPolygon: null,
			isVoronoiSelected: false,
			currentSeries: [],
			graphIsRatio: false,
			graphTitle: {
				text: ["All Districts"],
				align: "center",
				style: {
					fontSize: "16px",
					fontWeight: 300,
					margin: 20
				}
			},
			voronoiChanged: false,
			legend: {},
			selectedDistrict: null
		};
		this.xAxis = ["Dec", "Jan", "Feb", "Mar"];
		this.colors = [
			// ["#b3cde0", "#83d0c9", "#e3c9c9", "#ff8b94", "#8c9da9", "#8874a3", "#f1c27d"],
			["#b3cde0", "#83d0c9", "#e3c9c9", "#ff8b94", "#8874a3", "#f1c27d"],
			["#83d0c9", "#ff8b94", "#f1c27d"],
			["#8874a3", "#f1c27d"]
		];

		this.topTabToAttribute = {
			stock: "availability",
			"units given out": "volume",
			"stock out": "stockOut"
		};
		this.leftTabToAttribute = {
			population: "population",
			"all products": "allProductsSold",
			"sec level": "sec",
			"foot traffic": "footTraffic",
			accessibility: "accessibility"
		};
		this.myDivToFocus = React.createRef();
	}

	componentWillMount() {
		try {
			this.setState({ allData: allData, selectedData: allData }, () => {
				this.populateTable();
			});
		} catch (e) {
			console.error(e);
		}
	}

	componentDidUpdate() {
		let topTab = this.state.topTab;
		let leftTab = this.state.leftTab;
		if (this.props.topTabName !== topTab) {
			topTab = this.props.topTabName;
			this.setState({ topTab, topAttribute: this.topTabToAttribute[topTab] }, () => {
				if (!this.state.isDistrictMap) this.updateVoronoiColors();
			});
		}
		if (this.props.leftTab !== leftTab) {
			leftTab = this.props.leftTab;
			this.setState({ leftTab, leftAttribute: this.leftTabToAttribute[leftTab] }, () => {
				if (!this.state.isDistrictMap) this.updateVoronoiColors();
			});
		}
	}

	toggleDistrictMap = district => {
		if (district && district !== "") {
			if (this.state.allData[district.toLowerCase()]) {
				let key = this.state.allData[district.toLowerCase()].id;
				let selectedData = { [key]: this.state.allData[key] };
				let center = this.state.allData[key].center.marker.position;
				let zoom = Math.ceil(this.state.allData[key].boundary.area * 0.0002 * -1 + 11.006);
				this.setState(
					{
						isDistrictMap: false,
						selectedData: selectedData,
						googleMapCenter: center,
						googleMapZoom: zoom,
						isVoronoiSelected: false,
						lastClickedPolygon: null,
						selectedDistrict: key
					},
					this.updateVoronoiColors
				);
			}
		} else {
			this.setState({
				isDistrictMap: true,
				selectedData: {},
				googleMapCenter: {},
				googleMapZoom: 7,
				isVoronoiSelected: false,
				lastClickedPolygon: null,
				selectedDistrict: null
			});
		}
	};

	updateVoronoiColors = () => {
		try {
			if (!this.state.topAttribute && !this.state.leftAttribute) return this.stockColors();
			else if (this.state.topAttribute === "availability") return this.stockColors();
			else if (this.state.topAttribute === "volume") return this.unitsGivenOutColors();
			// else if (this.state.topAttribute === "stockOut") return this.stockOutColors();
			else if (this.state.topAttribute === "stockOut") return this.newStockOutColor();
			else if (this.state.leftAttribute === "population") return this.populationColors();
			else if (this.state.leftAttribute === "allProductsSold") return this.allProductsSoldColors();
			else if (this.state.leftAttribute === "footTraffic") return this.footTrafficColors();
			else if (this.state.leftAttribute === "sec") return this.secColors();
			else if (this.state.leftAttribute === "accessibility") return this.accessibilityColors();
		} catch (e) {
			console.error(e);
		}
	};

	createLegend = (dataMin, dataMax, index) => {
		let decimals = 5;
		let legend = [];
		let colorDelta = (dataMax - dataMin) / this.colors[index].length;
		if (colorDelta >= 1) decimals = 0;
		else if (colorDelta >= 0.01) decimals = 2;
		for (let i = 0; i < this.colors[index].length - 1; i++) {
			legend.push({
				text: `${this.roundOff(dataMin + colorDelta * i, decimals)} - ${this.roundOff(
					dataMin + colorDelta * (i + 1),
					decimals
				)}`,
				color: this.colors[index][i]
			});
		}
		legend.push({
			text: `${this.roundOff(dataMin + colorDelta * (this.colors[index].length - 1), decimals)} - ${this.roundOff(
				dataMin + colorDelta * this.colors[index].length,
				decimals
			)}`,
			color: this.colors[index][this.colors[index].length - 1]
		});
		return legend;
	};

	roundOff = (num, decimals) => {
		try {
			// if (decimals === 0) return num;
			let rounder = Math.pow(10, decimals);
			return Math.round((num + Number.EPSILON) * rounder) / rounder;
		} catch (e) {}
	};

	stockColors = () => {
		try {
			let { leftAttribute } = this.state;
			let selectedKey = Object.keys(this.state.selectedData)[0];
			let dataForState = this.state.selectedData;
			let selectedData = this.state.selectedData[selectedKey];
			let ranges = {};
			let attribute = "availability";
			let topRange = selectedData.data.ranges[attribute];
			let bottomRange = null;
			if (leftAttribute === "population") {
				bottomRange = selectedData.data.ranges.population;
				if (bottomRange.min === 0) ranges.min = 0;
				else ranges.min = (topRange.min / bottomRange.min) * 1000;
				ranges.max = (topRange.max / bottomRange.max) * 1000;
			} else if (leftAttribute === "allProductsSold") {
				bottomRange = selectedData.data.ranges.allProductsSold;
				if (bottomRange.min === 0) ranges.min = 0;
				else ranges.min = (topRange.min / bottomRange.min) * 100;
				ranges.max = (topRange.max / bottomRange.max) * 100;
			} else if (leftAttribute === "footTraffic") {
				bottomRange = selectedData.data.ranges.footTraffic;
				if (bottomRange.min === 0) ranges.min = 0;
				else ranges.min = (topRange.min / bottomRange.min) * 1000;
				ranges.max = (topRange.max / bottomRange.max) * 1000;
			} else if (leftAttribute === "sec") {
				return this.secColors;
			} else if (leftAttribute === "accessibility") {
				return this.accessibilityColors;
			} else {
				ranges = topRange;
			}
			if (ranges.max < ranges.min) {
				let temp = ranges.max;
				ranges.max = ranges.min;
				ranges.min = temp;
			}
			let legend = this.createLegend(ranges.min, ranges.max, 0);
			let keys = Object.keys(selectedData.locations);
			for (const key of keys) {
				let locationData = selectedData.locations[key].data;
				let value = locationData.average[attribute];
				if (leftAttribute === "population") {
					value = (value / locationData.population) * 1000;
				} else if (leftAttribute === "allProductsSold") {
					value = (value / locationData.average.allProductsSold) * 100;
				} else if (leftAttribute === "footTraffic") {
					value = (value / locationData.average.footTraffic) * 1000;
				}
				let increment = (ranges.max - ranges.min) / this.colors[0].length;
				for (let i = 0; i < this.colors[0].length; i++) {
					if (value <= ranges.min + increment * i) {
						dataForState[selectedKey].locations[key].boundary.style.fillColor = this.colors[0][i];
						break;
					}
				}
			}
			this.setState({ selectedData: dataForState, voronoiChanged: !this.state.voronoiChanged, legend });
		} catch (e) {
			console.error(e);
		}
	};

	unitsGivenOutColors = () => {
		try {
			let { leftAttribute } = this.state;
			let selectedKey = Object.keys(this.state.selectedData)[0];
			let dataForState = this.state.selectedData;
			let selectedData = this.state.selectedData[selectedKey];
			let ranges = {};
			let attribute = "volume";
			let topRange = selectedData.data.ranges[attribute];
			let bottomRange = null;
			if (leftAttribute === "population") {
				bottomRange = selectedData.data.ranges.population;
				if (bottomRange.min === 0) ranges.min = 0;
				else ranges.min = (topRange.min / bottomRange.min) * 1000;
				ranges.max = (topRange.max / bottomRange.max) * 1000;
			} else if (leftAttribute === "allProductsSold") {
				bottomRange = selectedData.data.ranges.allProductsSold;
				if (bottomRange.min === 0) ranges.min = 0;
				else ranges.min = (topRange.min / bottomRange.min) * 100;
				ranges.max = (topRange.max / bottomRange.max) * 100;
			} else if (leftAttribute === "footTraffic") {
				bottomRange = selectedData.data.ranges.footTraffic;
				if (bottomRange.min === 0) ranges.min = 0;
				else ranges.min = (topRange.min / bottomRange.min) * 1000;
				ranges.max = (topRange.max / bottomRange.max) * 1000;
			} else if (leftAttribute === "sec") {
				return this.secColors;
			} else if (leftAttribute === "accessibility") {
				return this.accessibilityColors;
			} else {
				ranges = topRange;
			}
			if (ranges.max < ranges.min) {
				let temp = ranges.max;
				ranges.max = ranges.min;
				ranges.min = temp;
			}
			let legend = this.createLegend(ranges.min, ranges.max, 0);
			let keys = Object.keys(selectedData.locations);
			for (const key of keys) {
				let locationData = selectedData.locations[key].data;
				let value = locationData.average[attribute];
				if (leftAttribute === "population") {
					value = (value / locationData.population) * 1000;
				} else if (leftAttribute === "allProductsSold") {
					value = (value / locationData.average.allProductsSold) * 100;
				} else if (leftAttribute === "footTraffic") {
					value = (value / locationData.average.footTraffic) * 1000;
				}
				let increment = (ranges.max - ranges.min) / this.colors[0].length;
				for (let i = 0; i < this.colors[0].length; i++) {
					if (value <= ranges.min + increment * i) {
						dataForState[selectedKey].locations[key].boundary.style.fillColor = this.colors[0][i];
						break;
					}
				}
			}
			this.setState({ selectedData: dataForState, voronoiChanged: !this.state.voronoiChanged, legend });
		} catch (e) {
			console.error(e);
		}
	};

	stockOutColors = () => {
		try {
			let { leftAttribute } = this.state;
			let attribute = "stockOut";
			let selectedKey = Object.keys(this.state.selectedData)[0];
			let dataForState = this.state.selectedData;
			let selectedData = this.state.selectedData[selectedKey];
			let ranges = {};
			let topRange = selectedData.data.ranges[attribute];
			let bottomRange = null;
			if (leftAttribute === "population") {
				bottomRange = selectedData.data.ranges.population;
				if (bottomRange.min === 0) ranges.min = 0;
				else ranges.min = (topRange.min / bottomRange.min) * 100000;
				ranges.max = (topRange.max / bottomRange.max) * 100000;
			} else if (leftAttribute === "allProductsSold") {
				bottomRange = selectedData.data.ranges.allProductsSold;
				if (bottomRange.min === 0) ranges.min = 0;
				else ranges.min = (topRange.min / bottomRange.min) * 1000;
				ranges.max = (topRange.max / bottomRange.max) * 1000;
			} else if (leftAttribute === "footTraffic") {
				bottomRange = selectedData.data.ranges.footTraffic;
				if (bottomRange.min === 0) ranges.min = 0;
				else ranges.min = (topRange.min / bottomRange.min) * 100000;
				ranges.max = (topRange.max / bottomRange.max) * 100000;
			} else if (leftAttribute === "sec") {
				return this.secColors;
			} else if (leftAttribute === "accessibility") {
				return this.accessibilityColors;
			} else {
				ranges = topRange;
			}
			if (ranges.max < ranges.min) {
				let temp = ranges.max;
				ranges.max = ranges.min;
				ranges.min = temp;
			}
			let legend = this.createLegend(ranges.min, ranges.max, 0);
			let keys = Object.keys(selectedData.locations);
			for (const key of keys) {
				let locationData = selectedData.locations[key].data;
				let value = locationData.average[attribute];
				if (leftAttribute === "population") {
					value = (value / locationData.population) * 1000;
				} else if (leftAttribute === "allProductsSold") {
					value = (value / locationData.average.allProductsSold) * 1000;
				} else if (leftAttribute === "footTraffic") {
					value = (value / locationData.average.footTraffic) * 100000;
				}
				let increment = (ranges.max - ranges.min) / this.colors[0].length;
				for (let i = 0; i < this.colors[0].length; i++) {
					if (value <= ranges.min + increment * i) {
						dataForState[selectedKey].locations[key].boundary.style.fillColor = this.colors[0][i];
						break;
					}
				}
			}
			this.setState({ selectedData: dataForState, voronoiChanged: !this.state.voronoiChanged, legend });
		} catch (e) {
			console.error(e);
		}
	};

	newStockOutColor = () => {
		try {
			let { leftAttribute } = this.state;
			if (leftAttribute === "sec") {
				return this.secColors;
			} else if (leftAttribute === "accessibility") {
				return this.accessibilityColors;
			}
			let attribute = "stockOut";
			let selectedKey = Object.keys(this.state.selectedData)[0];
			let dataForState = this.state.selectedData;
			let selectedData = this.state.selectedData[selectedKey];
			// let ranges = { min: Number.MAX_SAFE_INTEGER, max: 0 };
			let ranges = { min: 0, max: 100 };
			let data = {};
			let keys = Object.keys(selectedData.locations);
			for (const key of keys) {
				let locationData = selectedData.locations[key].data;
				let value = locationData.average[attribute];
				if (leftAttribute === "population") {
					value = (locationData[leftAttribute] / selectedData.data[leftAttribute]) * 100 * value * 100;
				} else if (leftAttribute === "allProductsSold") {
					value = 0;
					for (const [i, val] of locationData[attribute].entries()) {
						value += (locationData[leftAttribute][i] / selectedData.data[leftAttribute][i]) * val * 100;
					}
					// value = value / locationData[attribute].length;
					value = value * 100;
					// let districtSum = 0;
					// for (const val of selectedData.data[leftAttribute]) {
					// 	districtSum += val;
					// }
					// districtSum = districtSum / selectedData.data[leftAttribute].length;
					// value = (locationData.average[leftAttribute] / districtSum) * 100 * value * 100;
				} else if (leftAttribute === "footTraffic") {
					value = 0;
					for (const [i, val] of locationData[attribute].entries()) {
						value += (locationData[leftAttribute][i] / selectedData.data[leftAttribute][i]) * val * 100;
					}
					// value = value / locationData[attribute].length;
					value = value * 100;
				} else value = value * 100;
				let increment = (ranges.max - ranges.min) / this.colors[0].length;
				for (let i = 0; i < this.colors[0].length; i++) {
					if (value <= ranges.min + increment * i) {
						dataForState[selectedKey].locations[key].boundary.style.fillColor = this.colors[0][i];
						break;
					}
				}
			}
			let legend = this.createLegend(ranges.min, ranges.max, 0);
			this.setState({ selectedData: dataForState, voronoiChanged: !this.state.voronoiChanged, legend });
		} catch (e) {
			console.error(e);
		}
	};

	populationColors = () => {
		try {
			let selectedKey = Object.keys(this.state.selectedData)[0];
			let dataForState = this.state.selectedData;
			let selectedData = this.state.selectedData[selectedKey];
			let attribute = "population";
			let ranges = selectedData.data.ranges[attribute];
			let legend = this.createLegend(ranges.min, ranges.max, 0);
			let keys = Object.keys(selectedData.locations);
			for (const key of keys) {
				let locationData = selectedData.locations[key].data;
				let value = locationData[attribute];
				let increment = (ranges.max - ranges.min) / this.colors[0].length;
				for (let i = 0; i < this.colors[0].length; i++) {
					if (value <= ranges.min + increment * i) {
						dataForState[selectedKey].locations[key].boundary.style.fillColor = this.colors[0][i];
						break;
					}
				}
			}
			this.setState({ selectedData: dataForState, voronoiChanged: !this.state.voronoiChanged, legend });
		} catch (e) {
			console.error(e);
		}
	};

	allProductsSoldColors = () => {
		try {
			let selectedKey = Object.keys(this.state.selectedData)[0];
			let dataForState = this.state.selectedData;
			let selectedData = this.state.selectedData[selectedKey];
			let attribute = "allProductsSold";
			let ranges = selectedData.data.ranges[attribute];
			let legend = this.createLegend(ranges.min, ranges.max, 0);
			let keys = Object.keys(selectedData.locations);
			for (const key of keys) {
				let locationData = selectedData.locations[key].data;
				let value = locationData.average[attribute];
				let increment = (ranges.max - ranges.min) / this.colors[0].length;
				for (let i = 0; i < this.colors[0].length; i++) {
					if (value <= ranges.min + increment * i) {
						dataForState[selectedKey].locations[key].boundary.style.fillColor = this.colors[0][i];
						break;
					}
				}
			}
			this.setState({ selectedData: dataForState, voronoiChanged: !this.state.voronoiChanged, legend });
		} catch (e) {
			console.error(e);
		}
	};

	footTrafficColors = () => {
		try {
			let selectedKey = Object.keys(this.state.selectedData)[0];
			let dataForState = this.state.selectedData;
			let selectedData = this.state.selectedData[selectedKey];
			let attribute = "footTraffic";
			let ranges = selectedData.data.ranges[attribute];
			let legend = this.createLegend(ranges.min, ranges.max, 0);
			let keys = Object.keys(selectedData.locations);
			for (const key of keys) {
				let locationData = selectedData.locations[key].data;
				let value = locationData.average[attribute];
				let increment = (ranges.max - ranges.min) / this.colors[0].length;
				for (let i = 0; i < this.colors[0].length; i++) {
					if (value <= ranges.min + increment * i) {
						dataForState[selectedKey].locations[key].boundary.style.fillColor = this.colors[0][i];
						break;
					}
				}
			}
			this.setState({ selectedData: dataForState, voronoiChanged: !this.state.voronoiChanged, legend });
		} catch (e) {
			console.error(e);
		}
	};

	secColors = () => {
		try {
			let selectedKey = Object.keys(this.state.selectedData)[0];
			let dataForState = this.state.selectedData;
			let selectedData = this.state.selectedData[selectedKey];
			let attribute = "sec";
			let legend = [];
			legend[0] = { text: "SEC A", color: this.colors[1][0] };
			legend[1] = { text: "SEC B", color: this.colors[1][1] };
			legend[2] = { text: "SEC C", color: this.colors[1][2] };
			let keys = Object.keys(selectedData.locations);
			for (const key of keys) {
				let locationData = selectedData.locations[key].data;
				let value = locationData[attribute];
				if (value === "A")
					dataForState[selectedKey].locations[key].boundary.style.fillColor = this.colors[1][0];
				if (value === "B")
					dataForState[selectedKey].locations[key].boundary.style.fillColor = this.colors[1][1];
				if (value === "C")
					dataForState[selectedKey].locations[key].boundary.style.fillColor = this.colors[1][2];
			}
			this.setState({ selectedData: dataForState, voronoiChanged: !this.state.voronoiChanged, legend });
		} catch (e) {
			console.error(e);
		}
	};

	accessibilityColors = () => {
		try {
			let selectedKey = Object.keys(this.state.selectedData)[0];
			let dataForState = this.state.selectedData;
			let selectedData = this.state.selectedData[selectedKey];
			let attribute = "accessibility";
			let legend = [];
			legend[0] = { text: "< 1 km", color: this.colors[1][0] };
			legend[1] = { text: "1 - 2 km", color: this.colors[1][1] };
			legend[2] = { text: "> 2 km", color: this.colors[1][2] };
			let keys = Object.keys(selectedData.locations);
			for (const key of keys) {
				let locationData = selectedData.locations[key].data;
				let values = locationData[attribute];
				let index = values.indexOf(Math.max(...values));
				dataForState[selectedKey].locations[key].boundary.style.fillColor = this.colors[1][index];
			}
			this.setState({ selectedData: dataForState, voronoiChanged: !this.state.voronoiChanged, legend });
		} catch (e) {
			console.error(e);
		}
	};

	getDistrictIndex = district => {
		this.setState({ districtIndex: district }, () => {
			this.populateTable();
		});
	};

	populateTimeLine = () => {
		this.setState({
			timelineData: this.state.districtIndex !== null ? Submissions[this.state.districtIndex] : Submissions.All
		});
	};

	populateTable = () => {
		this.setState({
			tableData: this.state.districtIndex !== null ? Submissions[this.state.districtIndex] : Submissions.All
		});
		if (this.myDivToFocus.current) {
			this.myDivToFocus.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest"
			});
		}
	};

	handlePolygonClick = target => {
		try {
		} catch (e) {
			console.error(e);
		}
	};

	handleVoronoiClick = target => {
		try {
			if (this.state.isVoronoiSelected && target.id === this.state.lastClickedPolygon) {
				this.setState({ isVoronoiSelected: false, lastClickedPolygon: null });
			} else {
				if (this.state.isVoronoiSelected && target.id !== this.state.lastClickedPolygon) {
					this.setState({ lastClickedPolygon: target.id });
				} else {
					this.setState({ isVoronoiSelected: true, lastClickedPolygon: target.id });
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

	handleMarkerClick = target => {
		try {
		} catch (e) {
			console.error(e);
		}
	};

	setCardType = type => {
		this.setState({ cardType: type });
	};

	onClickCard = () => {
		this.setState({ collapse: !this.state.collapse });
	};

	toggleCard = () => {
		if (this.state.cardType === "enumerator") {
			return (
				<Card className="card-shadow-primary card-border mb-3">
					<CardHeader
						color="bg-royal"
						className="bg-warm-flame text-white"
						onClick={() => this.onClickCard()}
					>
						Enumerator Details
					</CardHeader>
					{/* <CardTitle ></CardTitle> */}
					<Collapse
						isOpen={this.state.collapse}
						onEntering={this.onEntering}
						onEntered={this.onEntered}
						onExiting={this.onExiting}
						onExited={this.onExited}
					>
						<CardBody onClick={() => this.onClickCard()}>
							<Row>
								<Col lg="6" style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
									<div
										style={{
											borderWidth: 15,
											borderColor: "#2a5298"
										}}
									>
										<img
											src={user}
											alt="Avatar 5"
											style={{
												width: 200,
												height: 200,
												borderRadius: 200,
												borderWidth: 5,
												borderColor: "#2a5298"
											}}
										/>
									</div>
								</Col>
								<Col lg="6">
									<div>
										<h5 className="menu-header-title">Shahzaib Khanzada</h5>
										<h6 className="menu-header-subtitle">Role: Enumerator</h6>
										<h6 className="menu-header-subtitle">Gender: Male</h6>
										<h6 className="menu-header-subtitle">DOB: 22-5-1995</h6>
										<h6 className="menu-header-subtitle">Phone Number: +92 321 444 555 6</h6>
										<h6 className="menu-header-subtitle">Email: shahzaib@gmail.com</h6>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Collapse>
					{/* <h6 className="text-center">Current state: {this.state.status}</h6> */}
				</Card>
			);
		} else if (this.state.cardType === "facility") {
			return (
				<Card className="card-shadow-primary card-border mb-3">
					<CardHeader
						color="bg-royal"
						className="bg-warm-flame text-white"
						onClick={() => this.onClickCard()}
					>
						Facility Details
					</CardHeader>
					{/* <CardTitle ></CardTitle> */}
					<Collapse
						isOpen={this.state.collapse}
						onEntering={this.onEntering}
						onEntered={this.onEntered}
						onExiting={this.onExiting}
						onExited={this.onExited}
					>
						<CardBody onClick={() => this.onClickCard()}>
							<Row>
								<Col lg="6">
									<div
										style={{
											borderWidth: 15,
											borderColor: "#2a5298"
										}}
									>
										<img
											src={medical}
											alt="Avatar 5"
											style={{
												width: 200,
												height: 200,
												borderRadius: 200,
												borderWidth: 5,
												borderColor: "#2a5298"
											}}
										/>
									</div>
								</Col>
								<Col lg="6">
									<div>
										<h5 className="menu-header-title">Al Medina Medicos</h5>
										<h6 className="menu-header-subtitle">Thatta</h6>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Collapse>
					{/* <h6 className="text-center">Current state: {this.state.status}</h6> */}
				</Card>
			);
		} else {
			return null;
		}
	};

	render() {
		return (
			<Container fluid>
				<Row style={{ marginTop: 20 }}>
					<Col lg="6">
						<Card>
							{this.state.isDistrictMap && (
								<DistrictMaps
									toggleDistrictMap={this.toggleDistrictMap}
									getDistrictIndex={district => this.getDistrictIndex(district)}
								/>
							)}
							{!this.state.isDistrictMap && (
								<GoogleMaps
									selectedData={this.state.selectedData}
									toggleDistrictMap={this.toggleDistrictMap}
									center={this.state.googleMapCenter}
									zoom={this.state.googleMapZoom}
									polygonClick={this.handlePolygonClick}
									voronoiClick={this.handleVoronoiClick}
									markerClick={this.handleMarkerClick}
									voronoiChanged={this.state.voronoiChanged}
									legend={this.state.legend}
									VoronoiClicked={selectedVoronoi => this.props.VoronoiClicked(selectedVoronoi)}
								/>
							)}
						</Card>
					</Col>
					{/* <Col sm="12" md="6" lg="6">
						<Card>{this.state.graph}</Card>
					</Col> */}
					{/* <Col sm="12" md="6" lg="6" style={{ justifyContent: "center", alignContent: "center" }}> */}
					<Col lg="6">
						<Card
						// style={{
						// 	justifyContent: "center",
						// 	alignContent: "center",
						// 	height: 610
						// }}
						>
							{!this.props.monitoring ? (
								<TimeLine
									data={this.state.timelineData}
									populateTable={district => this.populateTable(district)}
									setCardType={type => this.setCardType(type)}
								/>
							) : (
								<GraphComponent
									isDistrictMap={this.state.isDistrictMap}
									topTab={this.state.topTab}
									leftTab={this.state.leftTab}
									lastClickedPolygon={this.state.lastClickedPolygon}
									isVoronoiSelected={this.state.isVoronoiSelected}
									selectedDistrict={this.state.selectedDistrict}
									newStockOutColor={this.newStockOutColor}
								/>
							)}
						</Card>
					</Col>
				</Row>
				{this.toggleCard()}
				<div ref={this.myDivToFocus}>
					<TimelineTable data={this.state.tableData} />
				</div>
			</Container>
		);
	}
}

export default Analytics2;
