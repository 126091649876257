import React, { Fragment } from "react";
import ReactSelect from "../../../components/Dropdowns/ReactSelect";
const products = [
	{ value: "A", label: "OPV 2" },
	{ value: "B", label: "Measles-1" },
	{ value: "C", label: "Penta-3" }
];

class SearchBox extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			product: false
		};
	}

	render() {
		return (
			<Fragment>
				<ReactSelect
					options={products}
					placeholder={"OPV 2"}
					onChange={this.props.onProductChange}
					value={this.props.top.selectedProduct}
				/>
			</Fragment>
		);
	}
}

export default SearchBox;
