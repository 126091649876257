import React, { Component, Fragment } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, Collapse, CardHeader } from "reactstrap";
import { DistrictMaps } from "../../../components/DistrictMaps";
import TimeLine from "../../../components/Timeline";
import { TimelineTable } from "../../../components/Table/TimelineTable";
import { Submissions } from "../../../utils/static/districtSubmssions";
import { GoogleMaps } from "../../../components";
import allData from "../../../utils/static/total-data-min.json";
import avatar3 from "../../../architectuiassets/utils/images/avatars/1.jpg";
import user from "../../../assets/images/user.jpg";
import medical from "../../../assets/images/medical.jpg";

export default class Monitoring extends Component {
	constructor(props) {
		super(props);
		this.state = {
			districtIndex: null,
			tableData: Submissions.All,
			timelineData: Submissions.All,
			allData: {},
			isDistrictMap: true,
			selectedData: {},
			googleMapCenter: {},
			googleMapZoom: 7,
			// districtIndex: null,
			// tableData: Submissions.All,
			graph: null,
			topTab: "",
			leftTab: "",
			topAttribute: null,
			leftAttribute: null,
			cardType: "",
			collapse: true
		};
		this.myDivToFocus = React.createRef();
	}

	componentWillMount() {
		this.setState({ allData: allData, selectedData: allData });
	}

	toggleDistrictMap = district => {
		if (district && district !== "") {
			if (this.state && this.state.allData && this.state.allData[district.toLowerCase()]) {
				let key = this.state.allData[district.toLowerCase()].id;
				let selectedData = { [key]: this.state.allData[key] };
				let center = this.state.allData[key].center.marker.position;
				let zoom = Math.ceil(this.state.allData[key].boundary.area * 0.0002 * -1 + 11.006);
				this.setState({
					isDistrictMap: !this.state.isDistrictMap,
					selectedData: selectedData,
					googleMapCenter: center,
					googleMapZoom: zoom
				});
			}
		} else {
			this.setState({
				isDistrictMap: !this.state.isDistrictMap,
				selectedData: {},
				googleMapCenter: {},
				googleMapZoom: 7
			});
		}
	};

	getDistrictIndex = district => {
		console.log(district);
		this.setState({ districtIndex: district }, () => {
			this.populateTimeLine();
		});
	};

	populateTimeLine = () => {
		this.setState({
			timelineData: this.state.districtIndex !== null ? Submissions[this.state.districtIndex] : Submissions.All
		});
	};

	populateTable = district => {
		this.setState({
			tableData: Submissions[district]
		});
		if (this.myDivToFocus.current) {
			this.myDivToFocus.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest"
			});
		}
	};

	setCardType = type => {
		this.setState({ cardType: type });
	};

	onClickCard = () => {
		this.setState({ collapse: !this.state.collapse });
	};

	toggleCard = () => {
		if (this.state.cardType === "enumerator") {
			return (
				<Card className="card-shadow-primary card-border mb-3">
					<CardHeader
						color="bg-royal"
						className="bg-warm-flame text-white"
						onClick={() => this.onClickCard()}
					>
						Enumerator Details
					</CardHeader>
					{/* <CardTitle ></CardTitle> */}
					<Collapse
						isOpen={this.state.collapse}
						onEntering={this.onEntering}
						onEntered={this.onEntered}
						onExiting={this.onExiting}
						onExited={this.onExited}
					>
						<CardBody onClick={() => this.onClickCard()}>
							<Row>
								<Col lg="6" style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
									<div
										style={{
											borderWidth: 15,
											borderColor: "#2a5298"
										}}
									>
										<img
											src={user}
											alt="Avatar 5"
											style={{
												width: 200,
												height: 200,
												borderRadius: 200,
												borderWidth: 5,
												borderColor: "#2a5298"
											}}
										/>
									</div>
								</Col>
								<Col lg="6">
									<div>
										<h5 className="menu-header-title">Shahzaib Khanzada</h5>
										<h6 className="menu-header-subtitle">Role: Enumerator</h6>
										<h6 className="menu-header-subtitle">Gender: Male</h6>
										<h6 className="menu-header-subtitle">DOB: 22-5-1995</h6>
										<h6 className="menu-header-subtitle">Phone Number: +92 321 444 555 6</h6>
										<h6 className="menu-header-subtitle">Email: shahzaib@gmail.com</h6>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Collapse>
					{/* <h6 className="text-center">Current state: {this.state.status}</h6> */}
				</Card>
			);
		} else if (this.state.cardType === "facility") {
			return (
				<Card className="card-shadow-primary card-border mb-3">
					<CardHeader
						color="bg-royal"
						className="bg-warm-flame text-white"
						onClick={() => this.onClickCard()}
					>
						Facility Details
					</CardHeader>
					{/* <CardTitle ></CardTitle> */}
					<Collapse
						isOpen={this.state.collapse}
						onEntering={this.onEntering}
						onEntered={this.onEntered}
						onExiting={this.onExiting}
						onExited={this.onExited}
					>
						<CardBody onClick={() => this.onClickCard()}>
							<Row>
								<Col lg="6">
									<div
										style={{
											borderWidth: 15,
											borderColor: "#2a5298"
										}}
									>
										<img
											src={medical}
											alt="Avatar 5"
											style={{
												width: 200,
												height: 200,
												borderRadius: 200,
												borderWidth: 5,
												borderColor: "#2a5298"
											}}
										/>
									</div>
								</Col>
								<Col lg="6">
									<div>
										<h5 className="menu-header-title">Al Medina Medicos</h5>
										<h6 className="menu-header-subtitle">Thatta</h6>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Collapse>
					{/* <h6 className="text-center">Current state: {this.state.status}</h6> */}
				</Card>
			);
		} else {
			return null;
		}
	};

	toggleDistrictMap = district => {
		if (district && district !== "") {
			if (this.state.allData[district.toLowerCase()]) {
				let key = this.state.allData[district.toLowerCase()].id;
				let selectedData = { [key]: this.state.allData[key] };
				let center = this.state.allData[key].center.marker.position;
				let zoom = Math.ceil(this.state.allData[key].boundary.area * 0.0002 * -1 + 11.006);
				this.setState({
					isDistrictMap: !this.state.isDistrictMap,
					selectedData: selectedData,
					googleMapCenter: center,
					googleMapZoom: zoom
				});
			}
		} else {
			this.setState({
				isDistrictMap: !this.state.isDistrictMap,
				selectedData: {},
				googleMapCenter: {},
				googleMapZoom: 7
			});
		}
	};

	render() {
		return (
			<Fragment>
				<Container fluid>
					<Row style={{ marginTop: 20 }}>
						<Col lg="6">
							<Card>
								{this.state.isDistrictMap && (
									<DistrictMaps
										toggleDistrictMap={this.toggleDistrictMap}
										getDistrictIndex={district => this.getDistrictIndex(district)}
									/>
								)}
								{!this.state.isDistrictMap && (
									<GoogleMaps
										selectedData={this.state.selectedData}
										toggleDistrictMap={this.toggleDistrictMap}
										center={this.state.googleMapCenter}
										zoom={this.state.googleMapZoom}
									/>
								)}
							</Card>
						</Col>
						<Col sm="12" md="6" lg="6">
							<TimeLine
								data={this.state.timelineData}
								populateTable={district => this.populateTable(district)}
								setCardType={type => this.setCardType(type)}
							/>
						</Col>
					</Row>
					{this.toggleCard()}
					<div ref={this.myDivToFocus}>
						<TimelineTable data={this.state.tableData} />
					</div>
				</Container>
			</Fragment>
		);
	}
}
