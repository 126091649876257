import React from "react";
import Chart from "react-apexcharts";

const colors = ["#b3cde0", "#83d0c9", "#e3c9c9", "#ff8b94", "#8c9da9", "#8874a3", "#f1c27d"];
export default class StackedColumn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// series: [
			//   {
			//     name: 'Malir',
			//     data: [44, 55, 57, 56, 61]
			//   },
			//   {
			//       name: 'Korangi',
			//       data: [76, 85, 101, 98, 87]
			//   },
			//   {
			//       name: 'Thatta',
			//       data: [35, 41, 36, 26, 45]
			//   },
			//   {
			//       name: 'Shaheed Benazirabad',
			//       data: [58, 63, 60, 66, 105]
			//   },
			//   {
			//       name: 'Larkana',
			//       data: [91, 114, 94, 35, 41]
			//   },
			//   {
			//       name: 'Hyderabad',
			//       data: [48, 52, 53, 41, 101]
			//   },
			//   {
			//       name: 'Tharparkar',
			//       data: [66, 44, 33, 22, 11]
			//   }
			// ],
			series: [
				{
					name: "Total",
					data: [
						{ x: "Malir", y: 44 },
						{ x: "Korangi", y: 76 },
						{ x: "Thatta", y: 35 },
						{ x: "Shaheed Benazirabad", y: 58 },
						{ x: "Larkana", y: 91 },
						{ x: "Hyderabad", y: 48 },
						{ x: "Tharparkar", y: 66 },

						{ x: "Malir", y: 44 },
						{ x: "Korangi", y: 76 },
						{ x: "Thatta", y: 35 },
						{ x: "Shaheed Benazirabad", y: 58 },
						{ x: "Larkana", y: 91 },
						{ x: "Hyderabad", y: 48 },
						{ x: "Tharparkar", y: 66 },

						{ x: "Malir", y: 44 },
						{ x: "Korangi", y: 76 },
						{ x: "Thatta", y: 35 },
						{ x: "Shaheed Benazirabad", y: 58 },
						{ x: "Larkana", y: 91 },
						{ x: "Hyderabad", y: 48 },
						{ x: "Tharparkar", y: 66 },

						{ x: "Malir", y: 44 },
						{ x: "Korangi", y: 76 },
						{ x: "Thatta", y: 35 },
						{ x: "Shaheed Benazirabad", y: 58 },
						{ x: "Larkana", y: 91 },
						{ x: "Hyderabad", y: 48 },
						{ x: "Tharparkar", y: 66 },

						{ x: "Malir", y: 44 },
						{ x: "Korangi", y: 76 },
						{ x: "Thatta", y: 35 },
						{ x: "Shaheed Benazirabad", y: 58 },
						{ x: "Larkana", y: 91 },
						{ x: "Hyderabad", y: 48 },
						{ x: "Tharparkar", y: 66 }
					]
				},
				{
					name: "Stock Out",
					data: [
						{ x: "Malir", y: 44 },
						{ x: "Korangi", y: 76 },
						{ x: "Thatta", y: 35 },
						{ x: "Shaheed Benazirabad", y: 58 },
						{ x: "Larkana", y: 91 },
						{ x: "Hyderabad", y: 48 },
						{ x: "Tharparkar", y: 66 },

						{ x: "Malir", y: 44 },
						{ x: "Korangi", y: 76 },
						{ x: "Thatta", y: 35 },
						{ x: "Shaheed Benazirabad", y: 58 },
						{ x: "Larkana", y: 91 },
						{ x: "Hyderabad", y: 48 },
						{ x: "Tharparkar", y: 66 },

						{ x: "Malir", y: 44 },
						{ x: "Korangi", y: 76 },
						{ x: "Thatta", y: 35 },
						{ x: "Shaheed Benazirabad", y: 58 },
						{ x: "Larkana", y: 91 },
						{ x: "Hyderabad", y: 48 },
						{ x: "Tharparkar", y: 66 },

						{ x: "Malir", y: 44 },
						{ x: "Korangi", y: 76 },
						{ x: "Thatta", y: 35 },
						{ x: "Shaheed Benazirabad", y: 58 },
						{ x: "Larkana", y: 91 },
						{ x: "Hyderabad", y: 48 },
						{ x: "Tharparkar", y: 66 },

						{ x: "Malir", y: 44 },
						{ x: "Korangi", y: 76 },
						{ x: "Thatta", y: 35 },
						{ x: "Shaheed Benazirabad", y: 58 },
						{ x: "Larkana", y: 91 },
						{ x: "Hyderabad", y: 48 },
						{ x: "Tharparkar", y: 66 }
					]
				}
			],
			options: {
				colors: colors,
				chart: {
					type: "bar",
					stacked: true,
					toolbar: {
						show: true
					},
					zoom: {
						enabled: true
					}
				},
				xaxis: {
					categories: ["Dec", "Jan", "Feb", "Mar", "Apr"]
				},
				fill: {
					opacity: 1
				}
			}
		};
	}

	render() {
		return (
			<div id="chart">
				<Chart options={this.state.options} series={this.state.series} type="bar" width="100%" height="595px" />
			</div>
		);
	}
}
