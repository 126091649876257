import React from "react";
import { Marker, Polygon, InfoWindow } from "google-maps-react";
import GreenIcon from "../../../assets/images/green-circle-8.gif";
import BlueIcon from "../../../assets/images/blue-circle-8.gif";
import RedIcon from "../../../assets/images/red-dot-8.png";
import YellowIcon from "../../../assets/images/yellow-circle-8.gif";
import TransparentIcon from "../../../assets/images/transparent_icon.png";

const typeIcons = {
	commercial: GreenIcon,
	residential: BlueIcon,
	student: RedIcon
};

const segmentIcons = {
	a: GreenIcon,
	b: BlueIcon,
	c: YellowIcon,
	d: RedIcon
};

const DELTA = 0.0008333333333325754; // 100 meter difference in lat and long
const colorList = [["#f1c27d", "#b3cde0", "#83d0c9", "#e3c9c9", "#ff8b94", "#8c9da9", "#8874a3"]];

const createFranchiseMarkers = (data, selectedTypes) => {
	if (selectedTypes.length === 0) return [];
	selectedTypes = selectedTypes.map(type => type.toLowerCase());
	const filtetedData = data.filter(row => selectedTypes.includes(row.type.toLowerCase()));
	return filtetedData.map((row, index) => {
		row.lat = parseFloat(row.lat);
		row.lon = parseFloat(row.lon);
		row.type = row.type.toLowerCase();
		return (
			<Marker
				position={{ lat: row.lat, lng: row.lon }}
				key={`marker_${row.type}_${index}`}
				icon={typeIcons[row.type]}
			/>
		);
	});
};

const createMarkersFromType = (data, selectedTypes) => {
	if (selectedTypes.length === 0) return [];
	selectedTypes = selectedTypes.map(type => type.toLowerCase());
	const filtetedData = data.filter(row => selectedTypes.includes(row.type.toLowerCase()));
	return filtetedData.map((row, index) => {
		row.lat = parseFloat(row.lat);
		row.lon = parseFloat(row.lon);
		row.type = row.type.toLowerCase();
		return (
			<Marker
				position={{ lat: row.lat, lng: row.lon }}
				key={`marker_${row.type}_${index}`}
				icon={typeIcons[row.type]}
			/>
		);
	});
};

const createMarkersFromSegment = (data, selectedSegments) => {
	if (selectedSegments.length === 0) return [];
	selectedSegments = selectedSegments.map(segment => segment.toLowerCase());
	const filtetedData = data.filter(row => selectedSegments.includes(row.grade.toLowerCase()));
	return filtetedData.map((row, index) => {
		row.lat = parseFloat(row.lat);
		row.lon = parseFloat(row.lon);
		row.grade = row.grade.toLowerCase();
		return (
			<Marker
				position={{ lat: row.lat, lng: row.lon }}
				key={`marker_${row.grade}_${index}`}
				icon={segmentIcons[row.grade]}
			/>
		);
	});
};

const createVoronois = (polygons, onClickHandler, onHoverHandler, selectedPolygon) => {
	const polygonIds = Object.keys(polygons).filter(polygonId => polygonId !== selectedPolygon);
	return polygonIds.map(polygonId => {
		const voronoisColor = "#aa0000";
		const polygon = polygons[polygonId];
		return (
			<Polygon
				key={`voronoi_${polygonId}`}
				paths={polygon.boundary}
				strokeColor={voronoisColor}
				strokeOpacity={0.7}
				strokeWeight={2}
				fillColor={voronoisColor}
				fillOpacity={0}
				onClick={() => onClickHandler(polygonId)}
				onMouseover={() => onHoverHandler(polygonId)}
			/>
		);
	});
};

const createPopulationVoronois = polygons => {
	const ids = Object.keys(polygons);
	let maxPop = 0;
	ids.forEach(polygonId => (maxPop = Math.max(polygons[polygonId].population, maxPop)));
	const populationColors = [
		// { color: "#aaaaff", min: 15000, max: 126000 },
		// { color: "#7a79cf", min: 126001, max: 237000 },
		// { color: "#4f4da2", min: 237001, max: 348000 },
		// { color: "#252074", min: 348001, max: 459000 },
		// { color: "#000055", min: 459001, max: 570000 }
		{ color: colorList[0][0], min: 15000, max: 126000 },
		{ color: colorList[0][1], min: 126001, max: 237000 },
		{ color: colorList[0][2], min: 237001, max: 348000 },
		{ color: colorList[0][3], min: 348001, max: 459000 },
		{ color: colorList[0][4], min: 459001, max: 570000 }
	];
	return ids.map(polygonId => {
		const polygon = polygons[polygonId];
		const color = populationColors.find(c => {
			if (c.min <= polygon.population && c.max >= polygon.population) return true;
		});
		return (
			<Polygon
				key={`population_voronoi_${polygonId}`}
				paths={polygon.boundary}
				// strokeColor={"#000055"}
				strokeColor={"#aa0000"}
				strokeOpacity={0.7}
				strokeWeight={2}
				fillColor={color.color}
				fillOpacity={0.7}
				// fillOpacity={(polygon.population / maxPop) * 0.5 + 0.2}
			/>
		);
	});
};

const createSecPolygons = secs => {
	const colors = { A: "#83d0c9", B: "#f1c27d", C: "#ff8b94", D: "#88888800" };
	const polygons = [];
	Object.keys(secs).forEach(sec => {
		secs[sec].forEach((polygon, polygonIndex) => {
			polygons.push(
				<Polygon
					key={`sec_polygons_${sec}_${polygonIndex}`}
					paths={polygon}
					strokeWeight={0}
					fillColor={colors[sec]}
					fillOpacity={0.5}
				/>
			);
		});
	});
	return polygons;
};

const createBoxes = (boxes, onClickHandler) => {
	const boxColor = "#0000FF";
	return boxes.map(box => {
		const { lat, lng } = box;
		const id = `${lat}_${lng}`;
		return (
			<Polygon
				paths={[
					{ lat: lat, lng: lng },
					{ lat: lat - DELTA, lng: lng },
					{ lat: lat - DELTA, lng: lng + DELTA },
					{ lat: lat, lng: lng + DELTA },
					{ lat: lat, lng: lng }
				]}
				key={`box_${id}`}
				strokeColor={boxColor}
				strokeOpacity={0.7}
				strokeWeight={1}
				fillColor={boxColor}
				fillOpacity={0.3}
				onClick={() => onClickHandler(id)}
				// style={{ position: "relative", zIndex: 20000 }}
			/>
		);
	});
};

const createSelectedBox = (boxes, boxId) => {
	const boxColor = "#ff00FF";
	const box = boxes.find(b => `${b.lat}_${b.lng}` === boxId);
	const { lat, lng } = box;
	return (
		<Polygon
			paths={[
				{ lat: lat, lng: lng },
				{ lat: lat - DELTA, lng: lng },
				{ lat: lat - DELTA, lng: lng + DELTA },
				{ lat: lat, lng: lng + DELTA },
				{ lat: lat, lng: lng }
			]}
			key={`selected_box_${boxId}`}
			strokeColor={boxColor}
			// strokeColor={1}
			strokeWeight={3}
			fillColor={boxColor}
			fillOpacity={1}
		/>
	);
};

const createBoxMarkers = (boxes, top, bottom, left, right) => {
	const markers = [];
	boxes.forEach(box => {
		let { lat, lng } = box;
		lat = lat - DELTA / 2;
		lng = lng + DELTA / 2;

		if (lat >= bottom && lat <= top && lng >= left && lng <= right) {
			markers.push(<Marker position={{ lat, lng }} label={box.pop.toString()} icon={TransparentIcon} />);
		}
	});
	return markers;
};

const createVoronoiInfoWindow = polygon => {
	let lat = 0,
		lng = 0;
	polygon.boundary.forEach(coor => {
		lat += coor.lat;
		lng += coor.lng;
	});
	lat /= polygon.boundary.length;
	lng /= polygon.boundary.length;
	return (
		<InfoWindow position={{ lat, lng }} visible={true}>
			<span className="badge badge-secondary" style={{ fontSize: 18 }}>
				{polygon.population}
			</span>
		</InfoWindow>
	);
};

const updateVoronoiColors = (Map, Data, attribute, min, max, onClickHandler, selectedAccessLevel) => {
	try {
		let mKeys = Object.keys(Map);
		let colorListIndex = 0;
		let allColors = colorList[colorListIndex];
		let voronois = [];
		let inRangeCount = 0;
		let offRangeCount = 0;
		let dataMin = 0,
			dataMax = 0;
		let colorDelta = 0;
		let voronoisColor = allColors[allColors.length - 1];

		if (attribute === "market share by access") {
			dataMin = Data.aggregate.ranges[attribute].min[selectedAccessLevel];
			dataMax = Data.aggregate.ranges[attribute].max[selectedAccessLevel];
		} else {
			dataMin = Data.aggregate.ranges[attribute].min;
			dataMax = Data.aggregate.ranges[attribute].max;
		}
		colorDelta = (dataMax - dataMin) / allColors.length;
		let legend = createLegend(dataMin, dataMax, colorListIndex);
		// for (let i = 0; i < allColors.length - 1; i++) {
		// 	legend.push({
		// 		population: `${dataMin + Math.floor(colorDelta * i)} - ${dataMin + Math.floor(colorDelta * (i + 1))}`,
		// 		color: allColors[i]
		// 	});
		// }
		for (const key of mKeys) {
			const polygon = Map[key];
			let value = 0;
			if (attribute === "market share by access") {
				value = Data.locations[key].data.average[attribute][selectedAccessLevel];
			} else {
				value = Data.locations[key].data.average[attribute];
			}
			// if (Data.locations[key].data.average[attribute] < min || Data.locations[key].data.average[attribute] > max) {
			for (let i = 0; i < allColors.length - 1; i++) {
				if (value >= dataMin + colorDelta * i && value < dataMin + colorDelta * (i + 1)) {
					voronoisColor = allColors[i];
					break;
				}
			}
			if (value < min || value > max) {
				offRangeCount++;
				voronois.push(
					<Polygon
						key={`voronoi_${key}`}
						paths={polygon.boundary}
						// strokeColor={voronoisColor}
						strokeColor={"#000000"}
						strokeOpacity={0.7}
						strokeWeight={1}
						fillColor={voronoisColor}
						fillOpacity={0.0}
						onClick={() => onClickHandler(key)}
					/>
				);
			} else {
				inRangeCount++;
				voronois.push(
					<Polygon
						key={`voronoi_${key}`}
						paths={polygon.boundary}
						// strokeColor={voronoisColor}
						strokeColor={"#000000"}
						strokeOpacity={0.7}
						strokeWeight={1}
						fillColor={voronoisColor}
						fillOpacity={0.5}
						onClick={() => onClickHandler(key)}
					/>
				);
			}
		}
		return { voronois, inRangeCount, offRangeCount, legend };
	} catch (e) {
		console.error(e);
		throw e;
	}
};

// dataMin = minimum value of data
// dataMax = maximum value of data
// index = colorList is an array of arrays, with each array containing a list of colors. Index will select which color list you want to use for your legend
const createLegend = (dataMin, dataMax, index) => {
	let legend = [];
	let colorDelta = (dataMax - dataMin) / colorList[index].length;
	for (let i = 0; i < colorList[index].length - 1; i++) {
		legend.push({
			population: `${dataMin + Math.floor(colorDelta * i)} - ${dataMin + Math.floor(colorDelta * (i + 1))}`,
			color: colorList[index][i]
		});
	}
	return legend;
};

export {
	createMarkersFromType,
	createMarkersFromSegment,
	createVoronois,
	createBoxes,
	createBoxMarkers,
	createPopulationVoronois,
	createSecPolygons,
	createVoronoiInfoWindow,
	updateVoronoiColors,
	createSelectedBox,
	createFranchiseMarkers
};
