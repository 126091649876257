import React, {Component} from 'react';
import Chart from 'react-apexcharts'
const colors = [
    "#b3cde0",
      "#83d0c9",
      "#e3c9c9",
      "#ff8b94",
      "#8c9da9",
      "#8874a3",
      "#f1c27d",
  ]
class StackedArea extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options2: {
                colors: colors,
                chart: {
                    type: 'area',
                    stacked: true,
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                xaxis: {
                    categories: ['Dec', 'Jan', 'Feb', 'Mar', 'Apr']
                }
            },
            series2: [
                {
                    name: 'Malir',
                    data: [44, 55, 57, 56, 61]
                }, 
                {
                    name: 'Korangi',
                    data: [76, 85, 101, 98, 87]
                }, 
                {
                    name: 'Thatta',
                    data: [35, 41, 36, 26, 45]
                }, 
                {
                    name: 'Shaheed Benazirabad',
                    data: [58, 63, 60, 66, 105]
                }, 
                {
                    name: 'Larkana',
                    data: [91, 114, 94, 35, 41]
                }, 
                {
                    name: 'Hyderabad',
                    data: [48, 52, 53, 41, 101]
                }, 
                {
                    name: 'Tharparkar',
                    data: [66, 44, 33, 22, 11]
                }
            ],
        }
    }

    render() {

        return (
            <div className="bar">
                <Chart options={this.state.options2} series={this.state.series2} type="area"  width="100%"/>
            </div>
        );
    }
}

export default StackedArea;
