import React, { Fragment } from "react";
import cx from "classnames";
// import Ionicon from 'react-ionicons';
import { IoIosGrid, IoIosNotificationsOutline } from "react-icons/io";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Nav, Button, NavItem } from "reactstrap";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import city3 from "../../../architectuiassets/utils/images/dropdown-header/city3.jpg";

import Tabs from "react-responsive-tabs";

// Dropdown Tabs Content
import ChatExample from "./TabsContent/ChatExample";
import TimelineEx from "./TabsContent/TimelineExample";
import SysErrEx from "./TabsContent/SystemExample";
import { Link } from "react-router-dom";

const tabsContent = [
	{
		title: "Messages",
		content: <ChatExample />
	},
	{
		title: "Events",
		content: <TimelineEx />
	},
	{
		title: "System Errors",
		content: <SysErrEx />
	}
];

function getTabs() {
	return tabsContent.map((tab, index) => ({
		title: tab.title,
		getContent: () => tab.content,
		key: index
	}));
}

class HeaderDots extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			checked: false,
			isToggleOn: true,
			start_date: null,
			end_date: null
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}
	handleChange(checked) {
		this.setState({ checked });
	}

	handleClick() {
		this.setState(function(prevState) {
			return { isToggleOn: !prevState.isToggleOn };
		});
	}

	onDatesChange = async ({ startDate, endDate }) => {
		await this.setState({
			start_date: startDate,
			end_date: endDate
		});
	};

	render() {
		const { showDateSelector } = this.props;
		return (
			<Fragment>
				<div className="header-dots">
					{/* {showDateSelector && (
						<div className="mx-1">
							<DateRangePicker
								startDate={this.props.top.start_date}
								endDate={this.props.top.end_date}
								onDatesChange={this.props.changeDates}
								focusedInput={this.state.focusedInput}
								onFocusChange={focusedInput => this.setState({ focusedInput })}
								numberOfMonths={1}
								isOutsideRange={() => false}
								// isOutsideRange={(day) => day > moment()}
								// showClearDates
								small
								customInputIcon={
									<div className="icon-wrapper icon-wrapper-alt rounded-circle">
										<div className="icon-wrapper-bg bg-primary" />
										<IoIosGrid color="#ff6939" fontSize="18px" />
									</div>
								}
								noBorder
							/>
						</div>
					)} */}

					<div className="switchWrapper desktop">
						<div
							className="switch has-switch mb-2 mr-2"
							data-on-label="Analysis"
							data-off-label="Monitor"
							onClick={this.props.toggleSwitch}
						>
							<div
								className={cx("switch-animate", {
									"switch-on": this.props.top.isToggleOn,
									"switch-off": !this.props.top.isToggleOn
								})}
							>
								<input type="checkbox" />
								<span className="switch-left bg-primary">Analysis</span>
								<label>&nbsp;</label>
								<span className="switch-right bg-primary">Monitor</span>
							</div>
						</div>
					</div>
					<UncontrolledDropdown>
						{/* <DropdownToggle className="p-0 mr-2" color="link">
							<div className="icon-wrapper icon-wrapper-alt rounded-circle">
								<div className="icon-wrapper-bg bg-danger" />
								<IoIosNotificationsOutline color="#d92550" fontSize="18px" />
								<div className="badge badge-dot badge-dot-sm badge-danger">Notifications</div>
							</div>
						</DropdownToggle>
						<DropdownMenu right className="dropdown-menu-xl rm-pointers">
							<div className="dropdown-menu-header mb-0">
								<div className="dropdown-menu-header-inner bg-deep-blue">
									<div
										className="menu-header-image opacity-1"
										style={{
											backgroundImage: "url(" + city3 + ")"
										}}
									/>
									<div className="menu-header-content text-dark">
										<h5 className="menu-header-title">Notifications</h5>
										<h6 className="menu-header-subtitle">
											You have <b>21</b> unread messages
										</h6>
									</div>
								</div>
							</div>
							<Tabs
								tabsWrapperClass="body-tabs body-tabs-alt"
								transform={false}
								showInkBar={true}
								items={getTabs()}
							/>
							<Nav vertical>
								<NavItem className="nav-item-divider" />
								<NavItem className="nav-item-btn text-center">
									<Button size="sm" className="btn-shadow btn-wide btn-pill" color="focus">
										View Latest Changes
									</Button>
								</NavItem>
							</Nav>
						</DropdownMenu>
					 */}
						<DropdownToggle className="p-0 mr-2" color="link">
							<div className="icon-wrapper icon-wrapper-alt rounded-circle">
								<div className="icon-wrapper-bg bg-primary" />
								<IoIosGrid color="#ff6939" fontSize="18px" />
							</div>
						</DropdownToggle>
						<DropdownMenu right className="dropdown-menu-xl rm-pointers calendarInDropdown">
							<DateRangePicker
								startDate={this.props.top.start_date}
								endDate={this.props.top.end_date}
								onDatesChange={this.props.changeDates}
								focusedInput={this.state.focusedInput}
								onFocusChange={focusedInput => this.setState({ focusedInput })}
								numberOfMonths={1}
								isOutsideRange={() => false}
								// isOutsideRange={(day) => day > moment()}
								// showClearDates
								large
								// customInputIcon={
								// 	<div className="icon-wrapper icon-wrapper-alt rounded-circle">
								// 		<div className="icon-wrapper-bg bg-primary" />
								// 		<IoIosGrid color="#ff6939" fontSize="18px" />
								// 	</div>
								// }
								noBorder
							/>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
			</Fragment>
		);
	}
}

export default HeaderDots;
