import React, { PureComponent } from "react";
import shop from "../../../assets/images/conducted_at_icon.png";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import mapLegend from "../utils/mapLegendsData";
// import { RangeSlider } from "../../../components/Sliders/RangeSlider";
import { Col, Button } from "reactstrap";
import Loader from "../../../components/Loaders/Loader";
const { MyStyle } = require("../../../utils/constants/google-maps-styles.js");
class GoogleMap extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			bounds: {}
		};
	}

	setBounds = _ => {
		const bounds = new this.props.google.maps.LatLngBounds();
		const { markers, polygons } = this.props;
		markers.forEach(marker => {
			bounds.extend(marker.props.position);
		});
		polygons.forEach(polygon => {
			polygon.props.paths.forEach(coordinates => bounds.extend(coordinates));
		});
		this.setState({ bounds });
	};

	renderMapLegends = () => {
		if (this.props.selectedMenu === "population") {
			return (
				<Col
					style={{
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					{/* <h6 className="text-center mb-2 text-dark">Population Legend</h6> */}
					{/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}> */}
					{mapLegend.population.map((item, index) => (
						<span className={"mapLegends zoom ml-1 mb-1"} style={{ color: item.color }}>
							<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
							{item.population}
						</span>
					))}
					{/* </div> */}
				</Col>
			);
		} else if (this.props.selectedMenu === "seclevel") {
			return (
				<Col style={{ justifyContent: "center", alignItems: "center" }}>
					{/* <h6 className="text-center mb-2 text-dark">SEC Level Legend</h6> */}
					<div className="mapLegendsWrapper">
						{mapLegend.sec.map((item, index) => (
							<span className={"mapLegends zoom"} style={{ color: item.color }}>
								<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
								{item.population}
							</span>
						))}
					</div>
				</Col>
			);
		} else if (
			this.props.legend &&
			this.props.legend.length > 0 &&
			(this.props.selectedTab === 0 || this.props.selectedTab === 2)
		) {
			return (
				<Col style={{ justifyContent: "center", alignItems: "center" }}>
					{/* <h6 className="text-center mb-2 text-dark">Market Share Legend</h6> */}
					<div className="mapLegendsWrapper">
						{this.props.legend.map((item, index) => (
							<span className={"mapLegends zoom"} style={{ color: item.color }}>
								<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
								{item.population}
							</span>
						))}
					</div>
				</Col>
			);
		} else if (this.props.legend && this.props.legend.length > 0 && this.props.selectedTab === 1) {
			return (
				<Col style={{ justifyContent: "center", alignItems: "center" }}>
					{/* <h6 className="text-center mb-2 text-dark">Revenue Potential Legend</h6> */}
					<div className="mapLegendsWrapper">
						{this.props.legend.map((item, index) => (
							<span className={"mapLegends zoom"} style={{ color: item.color }}>
								<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
								{item.population}
							</span>
						))}
					</div>
				</Col>
			);
		}
	};

	render() {
		const {
			zoom,
			center,
			markers,
			polygons,
			infoWindows,
			onBoundsChanged,
			rangeSlider,
			franchises,
			boxes,
			loading,
			resetSelect
		} = this.props;
		const { bounds } = this.state;
		return (
			<div className="container p-0">
				<div className="rangeSlider row ml-1">
					{this.renderMapLegends()}
					{rangeSlider}
				</div>
				{loading ? (
					<div style={{ zIndex: 1000, alignSelf: "center", position: "absolute", top: 150, left: 200 }}>
						<Loader loading={loading} size={"xl"} />
					</div>
				) : (
					""
				)}
				<Map
					styles={MyStyle[0]}
					google={this.props.google}
					zoom={zoom}
					initialCenter={{ ...center }}
					onReady={map => this.setBounds()}
					bounds={bounds}
					mapTypeControl={false}
					fullscreenControl={false}
					streetViewControl={false}
					onIdle={onBoundsChanged}
				>
					{markers}
					{polygons}
					{infoWindows}
					{franchises}
					{boxes}
				</Map>
				<Button
					className="btn btn-primary"
					style={{ position: "absolute", bottom: 30, left: 10 }}
					onClick={resetSelect}
				>
					<i className="fas fa-undo"></i>
				</Button>
			</div>
		);
	}
}

GoogleMap.defaultProps = {
	zoom: 11,
	center: { lat: 31.5204, lng: 74.3587 },
	markers: [],
	polygons: [],
	onBoundsChanged: () => {}
};

export default GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY })(GoogleMap);
